import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import logo from "../../../Utility/images/ease/withBackground/company-logo-placeholder.png";
import { EditOutlined } from "@ant-design/icons";
import { Input, Tooltip, Button } from "antd";
import { getProfileInfo, updateProfileInfo } from "../../../redux/actions/API/profile";

interface IProfileInfoProps {
  userId: number;
  companyId: string;
  companyName: string;
  email: string;
  getProfileInfo: Function;
  updateProfileInfo: Function;
}

const ProfileInfo: React.FC<IProfileInfoProps> = ({
  userId,
  companyId,
  companyName,
  email,
  updateProfileInfo,
  getProfileInfo,
}) => {
  const [logoImage, setLogoImage] = useState(logo);
  const [editedCompanyName, setEditedCompanyName] = useState(companyName);
  const [editedEmail, setEditedEmail] = useState(email);
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  useEffect(() => {
    getProfileInfo({ userId, companyId });
  }, [userId, companyId, getProfileInfo]);

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitCompanyName = () => {
    const payload = { companyName: editedCompanyName };
    updateProfileInfo(payload);
    setIsEditingCompany(false);
  };

  const handleSubmitEmail = () => {
    const payload = { email: editedEmail };
    updateProfileInfo(payload);
    setIsEditingEmail(false);
  };

  return (
    <div className="mb-50">
      <div className="profileCover"></div>
      <div className="profileLogoBody">
        <div className="logoAndDetails">
          <Tooltip title="Change Logo">
            <label htmlFor="logoInput" style={{ cursor: "pointer" }}>
              <img className="profileLogo" src={logoImage} alt="Logo" />
            </label>
          </Tooltip>
          <div className="mt-10">
            <div className="linkText">
              {isEditingCompany ? (
                <div className="flexBox">
                  <Input
                    value={editedCompanyName}
                    onChange={(e) => setEditedCompanyName(e.target.value)}
                    autoFocus
                  />
                  <Button
                    onClick={handleSubmitCompanyName}
                    type="link" 
                  >
                    Save 
                  </Button>
                </div>
              ) : (
                <div>
                  {companyName}
                  <Tooltip title="Edit Company Name">
                    <EditOutlined
                      onClick={() => setIsEditingCompany(true)}
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
            <div>
              {isEditingEmail ? (
                <div className="flexBox mt-10">
                  <Input
                    value={editedEmail}
                    onChange={(e) => setEditedEmail(e.target.value)}
                    autoFocus  
                  />
                  <Button
                    onClick={handleSubmitEmail}
                    type="link" 
                  >
                    Save Email
                  </Button>
                </div>
              ) : (
                <div>
                  {email}
                  <Tooltip title="Edit Email">
                    <EditOutlined
                      onClick={() => setIsEditingEmail(true)}
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoChange}
          style={{ display: "none" }}
          id="logoInput"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data?.id,
  companyId: state.api.login.data?.companyId,
  companyName: state.api.login.data?.companyName,
  email: state.api.login.data?.email,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateProfileInfo: (payload: any) => dispatch(updateProfileInfo(payload)),
  getProfileInfo: (payload: any) => dispatch(getProfileInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
