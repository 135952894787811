import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./PaymentInvoice.css";
import { Button, Carousel, Col, Divider, Row } from "antd";
import {
  DownOutlined,
  EditOutlined,
  KeyOutlined,
  LockOutlined,
  ProfileOutlined,
  UpOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import tablet from "../../Utility/images/invoice-screen.jpeg";
import CreateInvoice from "../../Utility/images/create-invoice.png";
import Share from "../../Utility/images/share.png";
import Payment from "../../Utility/images/payment-section.png";
import PlayStore from "../../Utility/images/play-store.png";
import Google from "../../Utility/images/google.png";
import Star from "../../Utility/images/star.png";
import { Link, useNavigate } from "react-router-dom";

const faqData = [
  {
    question: "Will I be able to create Invoices for free ?",
    answer:
      "Yes, you don’t need to pay any additional charges for creating invoices only transaction fees will be applicable.",
  },
  {
    question: "Will my data be safe and secured?",
    answer:
      "Yes your data security is on us and it is stored in AWS environment along with SSL inscription.",
  },
  {
    question: "Can I convert my invoice in pdf and export ?",
    answer: "Yes, you can convert your invoice in PDF and export it.",
  },
  {
    question: "How do I accept payments through Ease Margin ?",
    answer:
      "After you’ve created and sent an invoice,your client will receive a link to pay online through integrated payment gateway .",
  },
  {
    question: "How can I contact Ease Margin ?",
    answer: "You can contact us at info@easemargin.com",
  },
];

const PaymentInvoice: React.FC = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<number | null>(null);
  const cardRefs = useRef<Array<HTMLDivElement | null>>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  const images = [CreateInvoice, Share, Payment];

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 432);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageClick = (index: any) => {
    setActiveIndex((index + 1) % images.length);
  };

  const navigateToSignUp = () => {
    navigate("/sign-in", { state: { isSignUp: true } });
  };

  const navigateToAboutUs = () => {
    navigate("/about_us");
  };

  const toggleCard = (index: number) => {
    setExpanded(expanded === index ? null : index);
    if (expanded !== index && cardRefs.current[index]) {
      cardRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  return (
    <>
      <Header />
      <div className="container-invoicing">
        <section className="introduction-section">
          <h2 className="heading-intro">
            <span className="span-intro">
              Create, Save, and Send your GST Invoices
            </span>{" "}
            <br />
            from today at Zero cost with US!
          </h2>
          <p className="paragh-intro">
            Upgrade your invoicing with our software that lets you to send
            invoices to your
            <br />
            customers and receive payments timely for your work.!
          </p>
          <div className="access-button">
            <Button
              type="primary"
              className="button-invoice"
              onClick={() => navigateToSignUp()}
            >
              Sign Up for free
            </Button>
            {/* <Button type="default" className="button-demo">
              Explore Demo Account
            </Button> */}
          </div>
          {/* <div className="review-container">
            <div className="circle playstore-container">
              <img
                src={PlayStore}
                alt="Playstore"
                className="play-store-icon"
              />
            </div>
            <div className="circle googleicon-container">
              <img src={Google} alt="google" className="google-icon" />
            </div>
            <div className="circle share-container">
              <img src={Share} alt="telegram" className="telegram-icon" />
            </div>
            <div className="text-rating">4.8/5</div>
            <div className="container-rating">
              <div className="container-star">
                <img src={Star} alt="star" className="image-star" />
                <img src={Star} alt="star" className="image-star" />
                <img src={Star} alt="star" className="image-star" />
                <img src={Star} alt="star" className="image-star" />
                <img src={Star} alt="star" className="image-star" />
              </div>
              <p className="text-customer">Customer Satisfaction</p>
            </div>
          </div> */}
        </section>

        <section className="image-section">
          <img src={tablet} alt="Tablet" className="image-tablet" />
        </section>

        <section className="detailing-section">
          <h2 className="heading-intro">
            <span className="span-intro">Quick and Easy Invoicing</span> with
            Lightning-Fast
            <br /> Payments!
          </h2>
          <Divider className="divider-invoice" />
          <div className="detailing-sub-container">
            <div className="detailing-mini-container">
              {!isMobileView ? (
                <Row gutter={24} justify="space-evenly" className="row-images">
                  <Col span={8}>
                    <img
                      src={CreateInvoice}
                      alt="create-invoice"
                      className="image-create-invoice"
                    />
                  </Col>
                  <Col span={8}>
                    <img
                      src={Share}
                      alt="share"
                      className="image-create-invoice"
                    />
                  </Col>
                  <Col span={8}>
                    <img
                      src={Payment}
                      alt="share"
                      className="image-create-invoice"
                    />
                  </Col>
                </Row>
              ) : (
                <Carousel arrows={true} autoplaySpeed={2000} autoplay={true} dots={true} afterChange={onChange} className="carousel-image">
                  <div>
                    <img
                      src={CreateInvoice}
                      alt="create-invoice"
                      className="image-create-invoice"
                    />
                  </div>
                  <div>
                    <img
                      src={Share}
                      alt="share"
                      className="image-create-invoice"
                    />
                  </div>
                  <div>
                    <img
                      src={Payment}
                      alt="share"
                      className="image-create-invoice"
                    />
                  </div>
                </Carousel>
              )}
            </div>
            <div className="detailing-mini-container-one">
              <div className="row-details">
                <div className="background-image">
                  <EditOutlined />
                </div>
                <div className="columnDetails">
                  <h6>Create</h6>
                  <div>
                    Choose professional invoicing format, add your company
                    details
                    <br />
                    and change fit to your requirement.
                  </div>
                </div>
              </div>
              <div className="row-details">
                <div className="background-image">
                  <VerticalAlignTopOutlined />
                </div>
                <div className="columnDetails">
                  <h6>Send</h6>
                  <div>
                    Let your customer get invoice through email or as a pdf on
                    WhatsApp.
                  </div>
                </div>
              </div>
              <div className="row-details">
                <div className="background-image">
                  <VerticalAlignBottomOutlined />
                </div>
                <div className="columnDetails">
                  <h6>Receive</h6>
                  <div>
                    Seamless payments receiving through UPI, Cards and Net
                    Banking, <br />
                    with our trusted gateways.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="data-security">
          <div>
            <h2 className="heading-intro">
              Your <span className="span-intro">data safety</span> is on us
            </h2>
          </div>
          <Divider className="divider-invoice" />
          <div className="data-sub-container">
            <div className="data-mini-container">
              <div className="row-data-security">
                <div className="background-image">
                  <ProfileOutlined />
                </div>
                <div className="columnDetails">
                  <div className="text-data-encryption">
                    Data is Stored in AWS.
                  </div>
                </div>
              </div>
              <div className="row-data-security">
                <div className="background-image">
                  <LockOutlined className="icon-data" />
                </div>
                <div className="columnDetails">
                  <div className="text-data-encryption ml-2">
                    Encrypt sensitive data and protect it from unauthorized
                    access.
                  </div>
                </div>
              </div>
            </div>
            <div className="data-mini-container-one">
              <div className="row-data-security">
                <div className="background-image">
                  <EditOutlined />
                </div>
                <div className="columnDetails">
                  <div className="text-data-encryption">
                    SSL Data Encryption that secures your server connections
                  </div>
                </div>
              </div>
              <div className="row-data-security">
                <div className="background-image">
                  <KeyOutlined />
                </div>
                <div className="columnDetails">
                  <div className="text-data-encryption">
                    Use strong password policies, multi-factor authentication.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-faqs">
          <h2 className="heading-intro">
            <span className="span-intro">Frequently Asked Questions</span>
          </h2>
          <p className="paragh-faq">
            If you can't find an answer that you're looking for, feel free to
            drop us a line.
          </p>
          <div className="faqs-button">
            <Button
              type="default"
              className="button-sub-faq"
              onClick={() => navigateToAboutUs()}
            >
              About the company
            </Button>
            <Button type="default" className="button-sub-faq ml-10">
              Contact Support
            </Button>
          </div>
          <div className="faq-cards">
            {faqData.map((faq, index) => (
              <div key={index} className="faq-card">
                <div className="faq-question" onClick={() => toggleCard(index)}>
                  <span>{faq.question}</span>
                  {expanded === index ? <UpOutlined /> : <DownOutlined />}
                </div>
                {expanded === index && (
                  <div className="faq-answer">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PaymentInvoice;
