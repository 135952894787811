import * as type from "../../types/API/contactUsType";

export function addContactUs(payload: any) {
  return {
    type: type.ADD_CONTACT_US,
    payload: payload,
  };
}

export function getContactUs(payload: any) {
  return {
    type: type.GET_CONTACT_US,
    payload: payload,
  };
}

export function editContactUs(payload: any) {
  return {
    type: type.EDIT_CONTACT_US,
    payload: payload,
  };
}

export function deleteContactUs(payload: any) {
  return {
    type: type.DELETE_CONTACT_US,
    payload: payload,
  };
}