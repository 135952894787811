import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  notification,
  Row,
  Select,
  theme,
  Typography,
} from "antd";
import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  UsergroupAddOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { setAppLocation } from "../../redux/actions/UI/appLocation";
import { loginUser, signupUser } from "../../redux/actions/API/auth";
import ease from "../../Utility/images/ease/svgfile/ease-primary.svg";
import { connect } from "react-redux";
import "./Signin.css";
import Loader from "../Loader/Loader";
import Header from "./Header";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Footer from "./Footer";
type NotificationType = "success" | "info" | "warning" | "error";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text } = Typography;

const { Option } = Select;

export interface ISignInProps {
  loginUser: Function;
  loginData: IDataResponse;
  signupUser: Function;
  signupData: IDataResponse;
}

const Signin: React.FC<ISignInProps> = ({
  loginUser,
  loginData,
  signupUser,
  signupData,
}) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const prevPropsRef = useRef<any>();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (prevPropsRef?.current?.loginData?.loading && !loginData?.loading) {
      if (!navigator.onLine) {
        openNotificationWithIcon(
          "error",
          "No internet connection! Please check your network."
        );
        setLoading(false);
        return;
      }
      if (loginData?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
        setHasError(true);
      } else {
        if (loginData?.data?.token) {
          navigate("/dashboard");
          openNotificationWithIcon("success", loginData?.data?.message);
        } else {
          setHasError(false);
        }
        setLoading(false);
      }
    }

    if (prevPropsRef?.current?.signupData?.loading && !signupData?.loading) {
      if (signupData?.error) {
        openNotificationWithIcon("error", signupData?.error?.message);
        setIsSignUp(true);
        setHasError(true);
        setLoading(false);
      } else {
        openNotificationWithIcon("success", signupData?.data?.message);
        setIsSignUp(false);
        setHasError(false);
        setLoading(false);
        form.resetFields();
      }
    }

    prevPropsRef.current = {
      loginData,
      signupData,
    };
  }, [loginData, signupData]);

  const screens = useBreakpoint();
  const { token } = useToken();

  const handleLogIn = () => {
    form
      .validateFields()
      .then((values) => {
        setHasError(false);
        setLoading(true);

        loginUser({
          email: values.username,
          password: values.password,
        });
        navigate("/sign-in");
      })
      .catch((errorInfo) => console.error("Validation Failed:", errorInfo));
  };

  const handleSignUp = () => {
    form
      .validateFields()
      .then((values) => {
        setHasError(false);
        setLoading(true);
        const signupPayload = {
          username: values.username,
          email: values.email,
          companyName: values.companyName,
          contactNo: values.contactNumber,
          businessType: values.businessType,
          password: values.password,
          name: values.name,
          role: '1'
        };
        console.log(signupPayload);
        signupUser(signupPayload);
        navigate("/login");
      })
      .catch((errorInfo) => {
        console.error("Please fill in all required fields!", errorInfo);
        setLoading(false);
      });
  };

  const loginForm = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        {screens.xs && <Header />}
        {screens.xs && <h4 className="loginHeading">Welcome to Ease Margin</h4>}
        <h2 className="loginTitle">Login</h2>
        <div className="formBody">
          {hasError && <div className="loginError">Invalid Credentials !</div>}
          <Form
            form={form}
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={handleLogIn}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please enter your username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                  setHasError(false);
                }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setHasError(false);
                }}
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <span
                className="link"
                onClick={() => {
                  setIsForgetPassword(true);
                  navigate("/forget-password");
                }}
              >
                Forgot password?
              </span>
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block={true} type="primary" onClick={handleLogIn} style={{maxWidth: '100px'}}>
                Log in
              </Button>
              <div className="loginText flexBox">
                <Text>Don't have an account?</Text>
                <span
                  className="link"
                  onClick={() => {
                    form.resetFields();
                    setIsSignUp(true);
                    setHasError(false);
                    navigate("/sign-in");
                  }}
                >
                  Sign up now
                </span>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  };

  const signUpForm = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        {screens.xs && <Header />}
        {screens.xs && <h4 className="loginHeading">Welcome to Ease Margin</h4>}
        <h2 className="loginTitle">Sign Up</h2>
        <div className="formBody">
          <Form
            form={form}
            name="normal_signup"
            layout="vertical"
            requiredMark="optional"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span>Company Name <span style={{ color: "red" }}>*</span></span>}
                  name="companyName"
                  rules={[{ required: true, message: "Please enter your company name!" }]}
                >
                  <Input
                    prefix={<UsergroupAddOutlined />}
                    placeholder="Please enter company name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={<span>Business Type <span style={{ color: "red" }}>*</span></span>}
                  name="businessType"
                  rules={[{ required: true, message: "Please select business type!" }]}
                >
                  <Select
                    placeholder="Business Type"
                    onChange={(value) => setBusinessType(value)}
                    value={businessType}
                    style={{minHeight: '42px'}}
                  >
                    <Option value="Manufacturer">Manufacturer</Option>
                    <Option value="Whole Seller">Whole Seller</Option>
                    <Option value="Trader">Trader</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={<span>Contact Person Name <span style={{ color: "red" }}>*</span></span>}
                  name="name"
                  rules={[{ required: true, message: "Please enter contact person name" }]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Contact person name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={<span>Email <span style={{ color: "red" }}>*</span></span>}
                  name="email"
                  rules={[
                    { required: true, message: "Please enter email address!" },
                    {
                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined />}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={<span>Contact Number <span style={{ color: "red" }}>*</span></span>}
                  name="contactNumber"
                  rules={[
                    { required: true, message: "Please enter your contact number!" },
                    {
                      pattern: /^[6-9]\d{9}$/,
                      message: "Please enter a valid contact number!",
                    },
                  ]}
                >
                  <Input
                    prefix={<PhoneOutlined />}
                    placeholder="Contact number"
                    onChange={(e) => setContactNumber(e.target.value)}
                    value={contactNumber}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={<span>Username <span style={{ color: "red" }}>*</span></span>}
                  name="username"
                  rules={[{ required: true, message: "Please enter your username!" }]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={<span>Password <span style={{ color: "red" }}>*</span></span>}
                  name="password"
                  rules={[{ required: true, message: "Please enter your Password!" }]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block={true} type="primary" onClick={handleSignUp} style={{maxWidth: '100px'}}>
                Sign up
              </Button>
              <div className="loginText flexBox">
                <Text>Already have an account?</Text>
                <span
                  className="link"
                  onClick={() => {
                    setIsSignUp(false);
                    setHasError(false);
                    form.resetFields();
                    navigate("/login");
                  }}
                >
                  Login now
                </span>
              </div>
            </Form.Item>
          </Form>

        </div>
      </>
    );
  };

  const forgotPasswordForm = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        {screens.xs && <Header />}
        {screens.xs && <h4 className="loginHeading">Reset Your Password</h4>}
        <h2 className="loginTitle">Forgot Password</h2>
        <div className="formBody">
          <Form
            form={form}
            name="forgot_password"
            onFinish={(values) => {
              console.log("Reset Password Request Sent for:", values.email);
              notification.success({
                message: "Reset link sent!",
                description: "Please check your email to reset your password.",
              });
            }}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your email!" },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Button
                block={true}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  navigate("/reset-password");
                  setShowResetPassword(true);
                }}
              >
                Send Reset Link
              </Button>
            </Form.Item>
            <Form.Item>
              <div className="flexBox">
                <div></div>
                <div>
                  <span
                    className="link"
                    onClick={() => {
                      setIsForgetPassword(false);
                      navigate("/login");
                    }}
                  >
                    Back to login
                  </span>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  };

  const resetPassword = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        {screens.xs && <Header />}
        {screens.xs && <h4 className="loginHeading">Welcome to Ease Margin</h4>}
        <h2 className="loginTitle">Reset Password</h2>
        <div className="formBody">
          <Form
            //form={form}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="newPassword"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please enter your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please re-enter your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Confirm Password"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button
                block={true}
                type="primary"
                onClick={() => {
                  navigate("/login");
                  setShowResetPassword(false);
                  setIsForgetPassword(false);
                }}
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  };

  return (
    <>
      <Header></Header>
      <div className="loginBox">
        {/* Left Side - Description */}
        <div className="left-section mhide">
          <h4>Welcome to EaseMargin</h4>
          <p>
            Streamline your manufacturing process, create documents for your
            sales and purchases, manage inventory, fetch real-time data, and
            generate reports as per requirement.
          </p>
          <h5 className="loginTitle">Why Choose Us?</h5>
          <ul>
            <li>
              <strong>Enhance Manufacturing Performance</strong>: Ease Margin's
              solution helps to improve productivity for small and medium-sized
              manufacturers.
            </li>
            <li>
              <strong>Comprehensive Features</strong>: Real-time inventory and
              production management, sales and purchase tracking, and effective
              user management.
            </li>
            <li>
              <strong>Powerful Reporting</strong>: Provides advanced and
              accurate reporting tools for making decisions based on data.
            </li>
            <li>
              <strong>Easy Integration</strong>: Connects with multiple
              accounting software and API access for integrations based on need.
            </li>
            <li>
              <strong>User-Friendly Experience</strong>: Provides an easy
              interface, customization options, and all-time high-quality user
              support for a seamless experience.
            </li>
          </ul>
        </div>

        {/* Right Side - Form Section */}
        <div className="right-section">
          <div className="form-container">
            {isSignUp
              ? signUpForm()
              : showResetPassword
                ? resetPassword()
                : isForgetPassword
                  ? forgotPasswordForm()
                  : loginForm()}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginData: state.api.login,
  signupData: state.api.signup,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAppLocation: (payload: any) => dispatch(setAppLocation(payload)),
  loginUser: (payload: any) => dispatch(loginUser(payload)),
  signupUser: (payload: any) => dispatch(signupUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
