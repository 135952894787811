import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PurchaseOrder from "../../Utility/images/purchaseorder.png";
import GoodsReceivedNote from "../../Utility/images/goodsreceivednotes.png";
import Goods from "../../Utility/images/goods.png";
import StocKeeping from "../../Utility/images/stockskeeping.png";
import ManageProducts from "../../Utility/images/productsmanagement.png";
import Variations from "../../Utility/images/variations.png";
import WOW from "../../Utility/js/wow";
import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./InventoryLanding.css";

const faqData = [
  {
    question:
      "What if I want to send the purchase order (PO) directly from Ease Margin ?",
    answer:
      "You can create the purchase order (PO) with our pre designed industry standard formats and share it easily with your suppliers through whatsapp or by mail just by a click.",
  },
  {
    question:
      "Can I set my existing purchase order (PO) number format in Ease Margin ?",
    answer:
      "Yes,you have an option to set your own purchase order number format and once set it will keep on auto changing the number for the next document.Also,you have an option to enter the  purchase order (PO) number manually each time. ",
  },
  {
    question:
      "How do I keep a record of materials coming from the supplier against the purchase order ?",
    answer:
      "You have an option to create goods received notes (GRN) which helps you to keep a record of items delivered to you by your supplier against the purchase order.Also,you have the option to create the quality report of the materials received by your supplier.",
  },
  {
    question:
      "Do I need to manually update my inventory each time after making a  purchase ? ",
    answer:
      "No,Ease Margin helps you to automate your inventory management.So once you create goods received notes or quality reports your inventory gets updated automatically. ",
  },
  {
    question:
      "Do I have an option to save my supplier invoice in Ease Margin ? ",
    answer:
      "Yes, you have an option to save your supplier’s invoice after creating the purchase order in the Ease Margin.",
  },
];

const PurchaseFeature: React.FC = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<number | null>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    try {
      const wow = new WOW.WOW();
      wow.init();
    } catch (error) {
      console.error("Error initializing WOW.js:", error);
    }
  }, []);

  useEffect(() => {
    const observers: IntersectionObserver[] = [];

    cardRefs.current.forEach((card, index) => {
      if (card) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              card.classList.add("inventory-card-transition");
            } else {
              card.classList.remove("inventory-card-transition");
            }
          });
        });
        observer.observe(card);
        observers.push(observer);
      }
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, []);

  const navigateToSignUp = () => {
    navigate("/sign-in", { state: { isSignUp: true } });
  };

  const toggleCard = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };
  return (
    <>
      <Header />
      <div className="inventory-landing-container">
        {/* <section className="section-inventory-details">
          <h2 className="heading-inventory-intro">Inventory Managment</h2>
          <div className="paragh-text-intro">
            Free inventory management software designed to help your business!
          </div>

          <div className="container-inventoryDetailed-cards">
            <div className="inventoryDetailedColumn">
              <div className="inventoryDetailedCardOne"></div>
              <div className="inventoryDetailedcardTwo"></div>
              <div className="inventoryDetailedcardTwo"></div>
              <div className="inventoryDetailedCardOne"></div>
            </div>

            <div></div>

            <div className="inventoryDetailedMainCard"></div>

            <div></div>

            <div className="inventoryDetailedColumn">
              <div className="inventoryDetailedCardThree"></div>
              <div className="inventoryDetailedcardFour"></div>
              <div className="inventoryDetailedcardFour"></div>
              <div className="inventoryDetailedCardThree"></div>
            </div>
          </div>
        </section> */}

        <section className="section-inventory-feature">
          <div className="text-inventory-feature">
            Inventory Tracking Software to keep track of your purchase activity.
          </div>
        </section>

        <h2 className="text-heading-feature">Features</h2>

        <section className="section-multiple-warehouse">
          <div className="inventory-container-row left-column wow fadeInRight txt-block">
            <img
              src={PurchaseOrder}
              alt="multiple-warehouse"
              className="image-multiple-warehouse"
            />
            <div className="inventory-container-column container-multiple-warehouse">
              <h4>Purchase Management</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Create purchase orders.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Create advance receipt document.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Download as pdf and share in WhatsApp / Email.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Punch supplier invoice.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Upload file and chat with teammates for communication.
                    </p>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </section>

        <section className="section-physical-stock">
          <div className="inventory-container-row right-column wow fadeInLeft txt-block">
            <div className="inventory-container-column container-phy-stock container-goods-received">
              <h4>Goods Received Notes and Quality Report</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Create GRN once you get the material in store.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Create Quality report and accept/reject material as per
                      store
                      <br /> ( auto updation of stocks ).
                    </p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      One to one mapping of documents as per PO and track
                      documents
                      <br /> in one place.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={GoodsReceivedNote}
              alt="physical-stock"
              className="image-good-received"
            />
          </div>
        </section>

        <section ref={wrapperRef} className="section-inventory-cards">
          <div className="heading-inventory-card">
            Try it for free. No payments required.
          </div>
          <Button
            className="button-inventory-signup"
            type="default"
            onClick={() => navigateToSignUp()}
          >
            Sign up for free
          </Button>
          <div className="inventory-card-row">
            {[
              {
                image: Goods,
                title: "Accurate stock keeping",
                description:
                  "Order confidently and save money by using EaseMargin’s inventory management software. EaseMargin gives your business control over your product stocks.",
              },
              {
                image: StocKeeping,
                title: "Improve your stock keeping",
                description:
                  "Improve speed and efficiency, remove error or fraud, and reduce time-consuming manual tasks. Simple and easy to use across your entire organization.",
              },
              {
                image: ManageProducts,
                title: "Manage all your products",
                description:
                  "Full control of all your products anywhere, anytime, from any device. See where every Rupee goes and monitor the bottom-line impact with real-time budgeting.",
              },
              {
                image: Variations,
                title: "Comprehensive product variation",
                description:
                  "Add and manage products with distinct variations like color, size, price & more. Curate and manage your evolving inventory from a single dashboard.",
              },
            ].map((card, index) => (
              <div
                key={index}
                ref={(el) => (cardRefs.current[index] = el)}
                className="inventory-cards inventory-card-transition"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="icon-inventory-cards"
                />
                <h3 className="headingh3">{card.title}</h3>
                <div className="paraghtextCards">{card.description}</div>
              </div>
            ))}
          </div>
        </section>

        <section className="section-faqs">
          <h2 className="heading-intro">
            <span className="span-intro">Frequently Asked Questions</span>
          </h2>
          <p className="paragh-faq">
            If you can't find an answer that you're looking for, feel free to
            drop us a line.
          </p>
          <div className="faqs-button">
            <Button type="default" className="button-sub-faq">
              About the company
            </Button>
            <Button type="default" className="button-sub-faq ml-10">
              Contact Support
            </Button>
          </div>
          <div className="faq-cards">
            {faqData.map((faq, index) => (
              <div key={index} className="faq-card">
                <div className="faq-question" onClick={() => toggleCard(index)}>
                  <span>{faq.question}</span>
                  {expanded === index ? <UpOutlined /> : <DownOutlined />}
                </div>
                {expanded === index && (
                  <div className="faq-answer">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
      <div className="inventory-footer-container">
        <Footer />
      </div>
    </>
  );
};

export default PurchaseFeature;
