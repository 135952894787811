import React, { useEffect } from "react";
import Header from "./Header";
import logo from "../../Utility/images/logo.png";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import "./Privacy.css";

const Privacy: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div id="privacy-policy">
      <Header />
      <div className="flex justify-center items-center min-h-screen bg-gray-100 p-8 mt-80 container-privacy">
        <div className="w-full max-w-3xl bg-white ">
          <h1 className="text-3xl font-bold mb-6 mt-10 heading-privacy">
            Privacy Policy
          </h1>
          <div className="paragh-privacy">
            <p className="mb-4">
              We respect and are committed towards protecting your privacy.
              Publishing, selling or renting any personal data or information to
              any third party, without your consent, is against our ethics. The
              privacy practices of this statement apply to our services
              available under the domain and subdomains of the Site. By visiting
              this Site you agree to be bound by the terms and conditions of
              this privacy policy. If you do not agree, please do not use or
              access our site.
              <br /> This privacy policy does not apply to sites maintained by
              other companies or organizations to which we link and we are not
              responsible for any personal information you submit to third
              parties via our website. Please ensure that you read the privacy
              policy of such other companies or organizations before submitting
              your details.
            </p>
            <p className="mb-4">
              This privacy policy describes the information, as part of the
              normal operation of our services, we collect from you and what may
              happen to that information. This policy is inter alia formulated
              and displayed, to inform you about our information
              collection/retention policies and practices so that you can make
              an informed decision, in relation to the sharing of your personal
              information with us.
              {/* <a
                href="mailto:Email@Website.com"
                className="text-blue-500 underline"
              >
                support@easemargin.com
              </a>
              . */}
            </p>
            <p className="mb-4">
              By accepting the privacy policy and the user agreement or the use
              of the site in general, you give your consent to our use and
              disclosure of your personal information in accordance with this
              privacy policy. This Privacy Policy is incorporated into and
              subject to the terms of the User Agreement. This privacy policy is
              effective upon acceptance of access by you to the site.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Privacy Guarantee
            </h2>
            <p className="mb-4">
              We agree that we will not sell or rent your personal information
              to third parties for their marketing purposes without your
              explicit consent. From time to time, we may reveal general
              statistical information about our Site and visitors, such as
              number of visitors, number and type of goods and services
              purchased, etc. Only those of our employees who need access to
              your information in order to perform their duties, are allowed
              such access. Any employee who violates our privacy and/or security
              policies is subjected to disciplinary action, including possible
              termination and civil and/or criminal prosecution.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Information We Collect
            </h2>
            <p className="mb-4">
              The Personal Information is used for two general purposes: to
              process your order, and to provide you with the best possible
              services. Unless otherwise stated explicitly, this policy applies
              to personal information as disclosed in any of the media.
            </p>
            <p className="mb-4">
              In furtherance of the confidentiality with which we treat Personal
              Information, we have put in place appropriate physical,
              electronic, and managerial procedures to safeguard and secure the
              information we collect online. We use data collection devices such
              as "cookies" on certain pages of the Site to help and analyze our
              web page flow, measure promotional effectiveness, and promote
              trust and safety. "Cookies" are small files placed on your hard
              drive that assist us in providing our services. We offer certain
              features that are only available through the use of a "cookie".
              Cookies can also help us provide information that is targeted to
              your interests. Most cookies are "session cookies," which means
              that they are automatically deleted from your hard drive at the
              end of a session.
            </p>
            <p className="mb-4">
              You are always free to decline our cookies if your browser
              permits. Additionally, you may encounter "cookies" or other
              similar devices on certain pages of the Site that are placed by
              third parties. For example, if you view a web page created by a
              user, there may be a "cookie" placed within that web page. We do
              not control the use of cookies by third parties. You acknowledge
              that you are disclosing Personal Information voluntarily. We
              identify and use your IP address to help diagnose problems with
              our server, and to administer our Website. Your IP address is also
              used to help identify you and to gather broad demographic
              information.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">COOKIE POLICY</h2>
            <p className="mb-4">
              Easemargin.com operates a strict privacy policy and we are
              committed to being transparent about how we use cookies on our
              website.
            </p>
            <ul className="list-disc list-inside mb-4 list-cookie">
              <li>● Why are cookies important?</li>
              <p className="mb-2">
                Cookies help you make your online experience more efficient and
                relevant to your interests. For instance, they are used to
                remember your preferences on sites you visit often, to remember
                your user ID and the contents of your shopping baskets, and to
                help you navigate between pages efficiently.
              </p>
              <li>● What is a Cookie?</li>
              <p className="mb-2">
                A cookie is a small file, or files on your computer, phone, or
                other device with a browser to save snippets of text for
                reference by the website you are visiting. All cookies have
                expiration dates in them that determine how long they stay in
                your browser:
              </p>
              <li>
                ● Session cookies - these are temporary cookies that expire (and
                are automatically erased) whenever you close your browser.
              </li>
              <br />
              <li>
                ● Persistent cookies - these usually have an expiration date and
                so stay in your browser until they expire, or until you manually
                delete them. For example we use persistent cookies to better
                understand usage patterns so that we can improve the site for
                our customers.
              </li>
              <br />
              <p className="mt-4">
                Cookies are grouped into the following categories:
              </p>
              <li>
                ● Essential - these are cookies that are required for the
                regular operation of our websites.
              </li>
              <br />
              <li>
                ● Functional - these remember your preferences, and are intended
                to make your experience on our websites better for you.
              </li>
              <br />
              <li>
                ● Analytics – these cookies are used for performance measurement
                to understand things including how many people visit our
                websites, how they navigate our sites, and what content is
                popular. This allows us to improve your experience with us.
                Additionally, you can see how Google Analytics (one of our
                analytics tools) uses cookie information when you use our
                partners' sites by visiting
                www.google.com/policies/privacy/partners, or any other URL
                Google may provide from time to time.
              </li>
              <br />
              <li>
                ● Advertising - these cookies enable us and our advertising
                partners to serve you with relevant advertisements that we think
                will interest you. You might see these advertisements on our
                sites on other sites you visit. These cookies record your visit
                to our website and the content you interact with. They may be
                placed by us, or by advertising partners with our permission.
              </li>
            </ul>
            <p className="mt-4">
              To ensure compliance with our policies, we restrict the use of
              third-party cookies to trusted partners. We also use third party
              information from third party sources to enable us to deliver
              advertising. These sources are validated by Third party, and not
              by us.
            </p>
            <p className="mt-4">
              To delete cookies from your browser, please note the following
              simple steps. The following steps are indicative for Google chrome
              and might vary depending on the different browsers that you may
              use. Please refer to Settings on your browser for further details.
            </p>
            <p className="mt-4">
            To ensure compliance with our policies, we restrict the use of third-party cookies to trusted partners.
            You may also change other settings related to Privacy and Security under the same section.
            </p>
            {/* <h2 className="text-2xl font-semibold mt-6 mb-4">Log Files</h2>
            <p className="mb-4">
              EaseMargin follows a standard procedure of using log files. These
              files log visitors when they visit websites. All hosting companies
              do this and a part of hosting services’ analytics. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users’ movement on the
              website, and gathering demographic information.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Cookies and Web Beacons
            </h2>
            <p className="mb-4">
              Like any other website, EaseMargin uses ‘cookies’. These cookies
              are used to store information including visitors’ preferences, and
              the pages on the website that the visitor accessed or visited. The
              information is used to optimize the users’ experience by
              customizing our web page content based on visitors’ browser type
              and/or other information.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              DoubleClick DART Cookie
            </h2>
            <p className="mb-4">
              Google is one of a third-party vendor on our site. It also uses
              cookies, known as DART cookies, to serve ads to our site visitors
              based upon their visit to www.easemargin.com and other sites on
              the internet. However, visitors may choose to decline the use of
              DART cookies by visiting the Google ad and content network Privacy
              Policy at the following URL –{" "}
              <a
                href="https://policies.google.com/technologies/ads"
                className="text-blue-500 underline"
              >
                https://policies.google.com/technologies/ads
              </a>
              .
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Advertising Partners Privacy Policies
            </h2>
            <p className="mb-4">
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of EaseMargin.
            </p>
            <p className="mb-4">
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on EaseMargin,
              which are sent directly to users’ browser. They automatically
              receive your IP address when this occurs. These technologies are
              used to measure the effectiveness of their advertising campaigns
              and/or to personalize the advertising content that you see on
              websites that you visit.
            </p>
            <p className="mb-4">
              Note that our website has no access to or control over these
              cookies that are used by third-party advertisers.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Third-Party Privacy Policies
            </h2>
            <p className="mb-4">
              EaseMargin's Privacy Policy does not apply to other advertisers or
              websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options. You may find a complete list of
              these Privacy Policies and their links here: Privacy Policy Links.
            </p>
            <p className="mb-4">
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers’
              respective websites. What Are Cookies?
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              CCPA Privacy Policy (Do Not Sell My Personal Information)
            </h2>
            <p className="mb-4">
              Under the CCPA, among other rights, California consumers have the
              right to:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>
                Request that a business that collects a consumer’s personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
              </li>
              <li>
                Request that a business delete any personal data about the
                consumer that a business has collected.
              </li>
              <li>
                Request that a business that sells a consumer’s personal data,
                not sell the consumer’s personal data.
              </li>
            </ul>
            <p className="mb-4">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              GDPR Privacy Policy (Data Protection Rights)
            </h2>
            <p className="mb-4">
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </li>
              <li>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </li>
              <li>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </li>
              <li>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </li>
              <li>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
            </ul>
            <p className="mb-4">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Children’s Information
            </h2>
            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>
            <p className="mb-4">
              EaseMargin does not knowingly collect any Personal Identifiable
              Information from children under the age of 13. If you think that
              your child provided this kind of information on our website, we
              strongly encourage you to contact us immediately and we will do
              our best efforts to promptly remove such information from our
              records.
            </p> */}
          </div>
        </div>
      </div>

      <div className="privacy-footer-container">
        <Footer />
      </div>
    </div>
  );
};
export default Privacy;
