import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./RefundAndCancellation.css";

const RefundAndCancellation: React.FC = () => {
  return (
    <>
      <Header />
      <section className="refundMainContainer">
        <h2 className="headingRefund">Cancellation and Refund Policy</h2>
        <div className="textRefundIntro">
          Thank you for choosing Ease Margin. We are committed to provide a best
          in class invoicing software solution,integrated with a safe and secure
          payment gateway , to make your work easier. By using our service, you
          agree to the following cancellation and refund terms :
        </div>
        <div className="paraghRefund">
          <b>1. Free Invoicing :</b>
          <br />
          Our invoicing software is provided free of charge. There are no fees
          associated with the use of the software itself.Therefore, no charges
          are refundable for the software.{" "}
        </div>
        <div className="paraghRefund">
          <b>2. Payment Gateway Service (Third Party) :</b>
          <br />
          We provide integration with a third-party payment gateway to
          facilitate payments between you and your customers.We are partnered
          with Cashfree and Razorpay for providing the payment gateway service.
          <br />
          While the invoicing software is free to use,kindly note that payment
          processing fees may apply when transactions are made via the
          integrated payment gateway. These fees are set by our payment gateway
          providers and Ease Margin is not liable for any refund for any such
          charges.
          <br />
          Any payment made via the payment gateway is subjected to the payment
          processor’s terms and conditions. If there is an issue with a
          transaction, such a dispute or chargeback, you must resolve it
          directly with the payment processor.
        </div>
        <div className="paraghRefund">
          <b>3. Disputes or Issues with Payments :</b>
          <br />
          In the case of a dispute or payment issues, please contact our support
          team at info@easemargin.com . We will assist you in resolving any such
          issues that arise in relation to the invoicing software or payment
          gateway integration.
        </div>
        <div className="paraghRefund">
          <b>4. Change to Policy :</b>
          <br />
          Ease Margin reserves the right to update or modify this policy at any
          time. Any changes to this policy will be informed to you via email or
          through updates posted within the software. Please check this page
          regularly for updates.
        </div>
      </section>
      <div className="refundFooter"><Footer /></div>
    </>
  );
};

export default RefundAndCancellation;
