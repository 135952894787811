import React, { useEffect, useRef, useState } from "react";
import { Drawer, Button, Space, Tooltip, Table, Alert, Input, Form, notification, Tag, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./manageUOM.css";
import { connect } from "react-redux";
import { addUOM, deleteUOM, editUOM, getUOM } from "../../../redux/actions/API/uomAction";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";
import { AppConstants } from "../../../Appconstants";

type NotificationType = "success" | "info" | "warning" | "error";

interface IManageUnitProps {
  title: string;
  onClose: () => void;
  open: boolean;
  companyId: number;
  uOMData: any;
  getUOM: Function;
  addUOM: Function;
  editUOM: Function;
  deleteUOM: Function;
  getUOMState: IDataResponse;
  addUOMState: IDataResponse;
  editUOMState: IDataResponse;
  deleteUOMState: IDataResponse;
}

const ManageUOM: React.FC<IManageUnitProps> = ({
  title,
  onClose,
  open,
  uOMData,
  getUOM,
  addUOM,
  editUOM,
  deleteUOM,
  getUOMState,
  addUOMState,
  editUOMState,
  deleteUOMState,
  companyId,
}) => {
  const prevPropsRef = useRef<IManageUnitProps>();
  const [isAddingNewUnit, setIsAddingNewUnit] = useState(false);
  const [newUnit, setNewUnit] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [isEditUOM, setIsEditUOM] = useState(false);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const formRef = useRef<any>(null);

  useEffect(() => {
    getUOM({ companyId: Number(companyId) });
  }, []);

  useEffect(() => {
    if (prevPropsRef.current?.getUOMState?.loading && !getUOMState.loading) {
      if (getUOMState.error?.length > 0) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }

    if (prevPropsRef.current?.addUOMState?.loading && !addUOMState.loading) {
      if (addUOMState?.error) {
        openNotificationWithIcon("error", 'Something went wrong');
        getUOM({ companyId: Number(companyId) });
      } else {
        getUOM({ companyId: Number(companyId) });
        openNotificationWithIcon("success", addUOMState?.data?.message);
        handleReset();
      }
    }

    if (prevPropsRef.current?.editUOMState?.loading && !editUOMState.loading) {
      if (editUOMState.error) {
        openNotificationWithIcon("error", "Something went wrong");
        getUOM({ companyId: Number(companyId) });
      } else {
        getUOM({ companyId: Number(companyId) });
        openNotificationWithIcon("success",  editUOMState?.data?.message);
        handleReset();
      }
    }

    if (prevPropsRef.current?.deleteUOMState?.loading && !deleteUOMState.loading) {
      if (deleteUOMState.error) {
        getUOM({ companyId: Number(companyId) });
        openNotificationWithIcon("error", 'Something went wrong');
      } else {
        getUOM({ companyId: Number(companyId) });
        openNotificationWithIcon("success", deleteUOMState?.data?.message);
      }
    }

    prevPropsRef.current = {
      title,
      onClose,
      open,
      uOMData,
      getUOM,
      addUOM,
      editUOM,
      deleteUOM,
      getUOMState,
      addUOMState,
      editUOMState,
      deleteUOMState,
      companyId,
    };
  }, [
    getUOMState,
    addUOMState,
    deleteUOMState,
    editUOMState,
    title,
    onClose,
    open,
    uOMData,
    getUOM,
    addUOM,
    editUOM,
    deleteUOM,
    companyId,
  ]);

  const handleEdit = (record: any) => {
    setNewUnit(record.code);
    setNewDescription(record.name);
    setIsEditUOM(true);
    setIsAddingNewUnit(true);
    setEditingId(record.id);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  const handleAddNewUnit = () => {
    formRef.current
      .validateFields()
      .then(() => {
        if (newUnit) {
          const duplicate = uOMData.some(
            (item: any) => item.code.toLowerCase() === newUnit.toLowerCase()
          );
          if (duplicate) {
            openNotificationWithIcon("error", "UOM already exists!");
            setLoading(false);
            return;
          }
          setLoading(true);
          if (isEditUOM && editingId !== null) {
            editUOM({
              uomId: editingId,
              code: newUnit,
              name: newDescription,
              companyId: Number(companyId),
              ip_address: "127.0.0.1",
            });
          } else {
            addUOM({
              code: newUnit,
              name: newDescription,
              companyId: Number(companyId),
              ip_address: "127.0.0.1",
            });
          }
          // handleReset();
        }
      })
      .catch((errorInfo: any) => {  
        setLoading(false);
      });
  };  

  const handleDelete = (id: number) => {
    setLoading(true);
    deleteUOM({ uomId: id });
  };

  const handleReset = () => {
    setIsAddingNewUnit(false);
    setIsEditUOM(false);
    setNewUnit("");
    setNewDescription("");
    setSearchTerm("");
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filteredUOMData = uOMData?.filter((item: any) => {
    return (
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.code.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const columns = [
    {
      title: "Unit of Measurement",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Description",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      width: "150px",
      render: (_: any, record: any) =>
        record.status === 0 ? (
          <div className="mobAddCategory">
            <Tag color="green" key="default">
              Default
            </Tag>
            <Tooltip
              title={"This is a default UOM, so you can not edit or delete it."}
            >
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
          </div>
        ) : (
          <Space size="middle">
            <Tooltip title="Edit">
              <span className="actionIcons" onClick={() => handleEdit(record)}>
                <EditOutlined />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <span className="actionIcons">
                  <DeleteOutlined />
                </span>
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
    },
  ];

  return (
    <>
      <Loader loading={loading}></Loader>
      <Drawer
        title={title}
        size={"large"}
        onClose={() => {
          onClose();
          handleReset();
        }}
        open={open}
        extra={
          <Space>
            <Button
            className="buttomCancelMobView"
              onClick={() => {
                onClose();
                handleReset();
              }}
            >
              Cancel
            </Button>
            {isAddingNewUnit && (
              <Button type="primary" onClick={() => handleAddNewUnit()}>
                {isEditUOM ? "Update" : "Submit"}
              </Button>
            )}
          </Space>
        }
      >
        {isAddingNewUnit && (
          <p className="backLinkSM" onClick={() => setIsAddingNewUnit(false)}>
            <ArrowLeftOutlined />
            Back to Units
          </p>
        )}
        <Alert message={AppConstants.UOM_DATA_MAPPING.ALERT_MESSAGE} />
        {isAddingNewUnit ? (
          <div className="add-unit-form mt-20">
            <Form ref={formRef} layout="vertical" className="mt-10">
              <Form.Item
                label="UOM Code"
                name="code"
                required
                rules={[
                  {
                    required: true,
                    message: "UOM is required.",
                  },
                  {
                    pattern: /^[A-Za-z][A-Za-z0-9]{1,9}$/,
                    message: "Enter valid UOM Start with letter, 2-10 chars.",
                  },
                ]}
              >
                <Input
                  placeholder="Enter UOM Code"
                  value={newUnit}
                  onChange={(e) => setNewUnit(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="UOM Name" name="name">
                <Input
                  placeholder="Enter UOM Name"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                />
              </Form.Item>
            </Form>
          </div>
        ) : (
          <>
            <div className="flexBox mt-20 mobAddCategory">
              <Input
                placeholder="Search Unit of measurement"
                onChange={(e) => onSearch(e.target.value)}
              />
              <Button
                type="primary"
                onClick={() => {
                  setIsAddingNewUnit(true);
                  setIsEditUOM(false);
                  setNewUnit("");
                  setNewDescription("");
                }}
              >
                Add New Unit
              </Button>
            </div>
            <Table
              bordered
              className="table-unit mt-20"
              columns={columns}
              dataSource={filteredUOMData}
              pagination={false}
              rowKey="id"
            />
          </>
        )}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login.data.companyId,
  uOMData: state.api.getUOM?.data?.reverse(),
  getUOMState: state.api?.getUOM,
  addUOMState: state.api.addUOM,
  editUOMState: state.api.editUOM,
  deleteUOMState: state.api.deleteUOM,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUOM: (payload: any) => dispatch(getUOM(payload)),
  addUOM: (payload: any) => dispatch(addUOM(payload)),
  editUOM: (payload: any) => dispatch(editUOM(payload)),
  deleteUOM: (payload: any) => dispatch(deleteUOM(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUOM);
