import {
  Breadcrumb,
  Button,
  Layout,
  Input,
  Space,
  Drawer,
  Form,
  Row,
  Col,
  Checkbox,
  Tooltip,
  Popconfirm,
  Table,
  TableProps,
  FormInstance,
  notification,
  Select,
  Tag,
  Pagination,
  Menu,
  Dropdown,
} from "antd";
import { Content } from "antd/es/layout/layout";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  ClockCircleOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import React from "react";
import { connect } from "react-redux";
import {
  addStores,
  deleteStores,
  editStores,
  getStores,
} from "../../redux/actions/API/storesActions";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
import { AppConstants } from "../../Appconstants";
import "./StoreManagement.css";
import debounce from "lodash/debounce";
import { stateData } from "../utility/StateData";
import { downloadExcel } from "../../Utility/downloadExcel";
import History from "./History/History";
import { TableRowSelection } from "antd/es/table/interface";
import BulkEdit from "./bulkEdit/BulkEdit";

type NotificationType = "success" | "info" | "warning" | "error";

interface DataType {
  key: string;
  storeId: number;
  storeName: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  pinCode: string;
  country: any;
  storeType: [];
}

interface IStoreProps {
  storesData: any[];
  getStores: Function;
  addStores: Function;
  editStore: Function;
  deleteStore: Function;
  userId: Number;
  getStoresState: IDataResponse;
  addStoreState: IDataResponse;
  editStoresState: IDataResponse;
  deleteStoresState: IDataResponse;
  companyId: any;
}

interface IStoreState {
  storeDrawerOpen: boolean;
  onEditStore: any | null;
  data: any[];
  storeName: string;
  addressLineOne: string;
  addressLineTwo: string;
  pinCode: string;
  city: string;
  state: string;
  country: string;
  loading: boolean;
  onChangeTitle: boolean;
  isCheckboxDisabled: boolean;
  storeType: any[];
  checked: boolean;
  stateList: string[];
  isDrawerVisible: boolean;
  searchData: any[];
  currentPage: number;
  pageSize: number;
  isHistoryOpen: boolean;
  selectedRowKeys: any[];
  showBulkActions: boolean;
  forBulkEditOpen: boolean;
  selectedStore: any;
  isMobileView: any;
}

const states: Record<string, string[]> = stateData;
const { Option } = Select;

class StoresManagement extends React.Component<IStoreProps, IStoreState> {
  private formRef = React.createRef<FormInstance>();
  debouncedSetState: any;
  constructor(props: IStoreProps) {
    super(props);
    this.state = {
      storeDrawerOpen: false,
      onEditStore: null,
      data: [],
      storeName: "",
      addressLineOne: "",
      addressLineTwo: "",
      pinCode: "",
      city: "",
      state: "",
      country: "",
      storeType: [],
      loading: true,
      onChangeTitle: false,
      isCheckboxDisabled: false,
      searchData: [],
      checked: true,
      stateList: states["India"],
      isDrawerVisible: false,
      currentPage: 1,
      pageSize: 10,
      isHistoryOpen: false,
      selectedRowKeys: [],
      showBulkActions: false,
      forBulkEditOpen: false,
      selectedStore: null,
      isMobileView: window.innerWidth <= 896,
    };
    this.debouncedSetState = debounce(this.setState.bind(this), 300);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobileView: window.innerWidth <= 896 });
  };

  getStoreType = (storeType: any) => {
    let types: string[] = [];

    if (
      storeType.includes(AppConstants.STORE_TYPE_MAPPING.IN_STOCK.ENTITY_ID)
    ) {
      types.push(AppConstants.STORE_TYPE_MAPPING.IN_STOCK.DISPLAY_STRING);
    }
    if (storeType.includes(AppConstants.STORE_TYPE_MAPPING.REJECT.ENTITY_ID)) {
      types.push(AppConstants.STORE_TYPE_MAPPING.REJECT.DISPLAY_STRING);
    }
    return types.join(", ");
  };

  generateBindData = () => {
    let storeData: any[] = [];
    this.props?.storesData?.map((store: any) => {
      storeData.push({
        storeId: store.id.toString(),
        name: store.name,
        addressLineOne: store.addressLineOne,
        addressLineTwo: store.addressLineTwo,
        pincode: store.pincode,
        city: store.city,
        state: store.state,
        country: store.country,
        itemCount: store.itemCount,
        storeType: this.getStoreType(
          store.storeType ? store.storeType?.split(",") : []
        ),
        storeAddress: `${store.addressLineOne}, ${store.addressLineTwo}, ${store.pincode}, ${store.city}, ${store.state}, ${store.country}`,
      });
    });
    return storeData;
  };

  openHistoryDrawer = (data: any) => {
    this.setState({ isHistoryOpen: true, selectedStore: data.storeId });
  };

  closeHistoryDrawer = () => {
    this.setState({ isHistoryOpen: false, selectedStore: null });
  };

  onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    this.setState({
      selectedRowKeys: newSelectedRowKeys,
    });
  };

  rowSelection: TableRowSelection<any> = {
    onChange: this.onSelectChange,
  };

  columns: TableProps<DataType>["columns"] = [
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Store Type",
      dataIndex: "storeType",
      key: "storeType",
      render: (data: any) => {
        const statusArray = data?.split(", ");
        return (
          <>
            {statusArray.map((status: any, index: number) => (
              <Tag key={status} color={status === "In Stock" ? "green" : "red"}>
                {`${status} : ${index + 1}`}
              </Tag>
            ))}
          </>
        );
      },
      sorter: (a: any, b: any) => {
        const typeA = a.storeType || "";
        const typeB = b.storeType || "";
        return typeA.localeCompare(typeB);
      },
    },
    {
      title: "Items",
      dataIndex: "itemCount",
      key: "itemCount",
      sorter: (a: any, b: any) => a.name.localeCompare(b.itemCount),
    },
    {
      title: "Store Address",
      dataIndex: "storeAddress",
      key: "storeAddress",
      sorter: (a: any, b: any) => a.storeAddress.localeCompare(b.storeAddress),
    },
    {
      title: "Actions",
      key: "action",
      width: "120px",
      render: (data: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="Edit Details">
            <span
              className="actionIcons"
              onClick={() => {
                let storeData: any = [];
                if (data.storeType.includes("In Stock")) {
                  storeData.push(1);
                }
                if (data.storeType.includes("Reject")) {
                  storeData.push(2);
                }
                this.setState(
                  {
                    storeType: storeData,
                  },
                  () => {
                    this.handleEdit(data);
                  }
                );
              }}
            >
              <EditOutlined />
            </span>
          </Tooltip>
          <Tooltip title="History">
            <span
              className="actionIcons"
              onClick={() => this.setState({ isHistoryOpen: true })}
            >
              <ClockCircleOutlined />
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => this.handleDelete(data.storeId)}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  componentDidMount(): void {
    this.props.getStores({ companyId: Number(this.props.companyId) });
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(
    prevProps: Readonly<IStoreProps>,
    prevState: Readonly<IStoreState>,
    snapshot?: any
  ): void {
    if (prevState.selectedRowKeys !== this.state.selectedRowKeys) {
      this.setState({ showBulkActions: this.state.selectedRowKeys.length > 0 });
    }

    if (
      prevProps.getStoresState?.loading &&
      !this.props.getStoresState?.loading
    ) {
      if (this.props.getStoresState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to get store");
      } else {
        this.setState({
          loading: false,
          isCheckboxDisabled: this.props?.storesData?.length > 0 ? false : true,
          searchData: this.generateBindData(),
        });
      }
    }

    if (
      prevProps.addStoreState?.loading &&
      !this.props.addStoreState?.loading
    ) {
      this.setState({
        loading: false,
      });
      if (this.props.addStoreState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.addStoreState?.error?.message
        );
        this.setState({
          loading: false,
        });
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.formRef.current?.resetFields();
        this.setState({
          storeDrawerOpen: false,
          loading: false,
        });
        this.openNotificationWithIcon("success", "Store added successfully");
      }
    }

    if (
      prevProps.editStoresState?.loading &&
      !this.props.editStoresState?.loading
    ) {
      this.setState({
        loading: false,
      });
      if (this.props.editStoresState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.editStoresState?.error?.message
        );
        this.setState({
          loading: false,
          onChangeTitle: true,
        });
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.setState({
          loading: true,
          storeDrawerOpen: false,
          onEditStore: null,
        });
        this.openNotificationWithIcon("success", "Store updated successfully");
      }
    }

    if (
      prevProps.deleteStoresState?.loading &&
      !this.props.deleteStoresState?.loading
    ) {
      this.setState({
        loading: false,
      });
      if (this.props.deleteStoresState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete store");
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.setState({
          loading: true,
        });
        this.openNotificationWithIcon("success", "Store deleted successfully");
      }
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (searchItem: string) => {
    const filteredData = this.generateBindData().filter((store: any) => {
      return (
        store.name.toLowerCase().includes(searchItem.toLowerCase()) ||
        store.storeType.toLowerCase().includes(searchItem.toLowerCase()) ||
        store.storeAddress.toLowerCase().includes(searchItem.toLowerCase())
      );
    });
    this.setState({ searchData: filteredData });
  };

  handleEdit = (values: any) => {
    this.setState(
      {
        storeDrawerOpen: true,
        onChangeTitle: true,
        onEditStore: values,
      },
      () => {
        this.formRef?.current?.setFieldsValue({
          storeName: values.name,
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          pincode: values.pincode,
          city: values.city,
          state: values.state,
          country: values.country,
          storeType: values.storeType
            .split(", ")
            .map((item: string) => {
              item = item.trim(); // Trim the item
              if (item === "In Stock") return 1; // Map "In Stock" to 1
              if (item === "Reject") return 2; // Map "Reject" to 2
              return null; // Handle unexpected values (optional)
            })
            .filter(Boolean), // Remove any null/undefined values
        });
      }
    );
  };

  handleDelete = (storeId: number) => {
    this.setState({
      loading: true,
    });
    this.props.deleteStore({
      storeId: storeId,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      storeDrawerOpen: false,
      onEditStore: null,
    });
    this.formRef.current?.resetFields();
  };

  // Add Store
  handleAddStore = () => {
    this.setState(
      {
        storeDrawerOpen: true,
        onEditStore: null,
        onChangeTitle: false,
        storeType: this.props.storesData?.length == 0 ? [2] : [],
      },
      () => {
        this.formRef.current?.resetFields();
        if (this.props?.storesData?.length === 0) {
          this.formRef?.current?.setFieldsValue({
            storeType: "Reject"
              .split(", ")
              .map((item: string) => {
                item = item.trim(); // Trim the item
                if (item === "In Stock") return 1; // Map "In Stock" to 1
                if (item === "Reject") return 2; // Map "Reject" to 2
              })
              .filter(Boolean), // Remove any null/undefined values
          });
        }
      }
    );
  };

  handleSubmit = () => {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        this.setState({
          loading: true,
        });
        if (this.state.onEditStore || this.state.onChangeTitle) {
          // Edit existing store
          this.props.editStore({
            companyId: Number(this.props.companyId),
            storeId: this.state.onEditStore.storeId,
            name: values.storeName,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            storeType: this.state.storeType.toString(),
            ip_address: "127.0.0.1",
            status: 1,
          });
        } else {
          // Add new store
          this.props.addStores({
            companyId: Number(this.props.companyId),
            storeName: values.storeName,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            storeType: this.state.storeType.toString(),
            ip_address: "127.0.0.1",
            status: 1,
          });
          // Add the new store to the array
          this.setState((prevState) => ({
            data: [
              ...prevState.data,
              {
                id: Date.now(), // unique ID
                storeName: values.storeName,
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                pinCode: values.pincode,
                city: values.city,
                state: values.state,
                country: values.country,
                storeType: values.storeType?.join(","),
                ip_address: "127.0.0.1",
                status: 1,
              },
            ],
          }));
        }
      })
      .catch((errorInfo) => {
        this.setState({
          loading: false,
        });
      });
  };

  openBulkUploadDrawer = () => {
    this.setState({
      isDrawerVisible: true,
    });
  };

  closeBulkUploadDrawer = () => {
    this.setState({ isDrawerVisible: false });
  };

  onPageChange = (page: number, pageSize: number) => {
    this.setState({ currentPage: page });
    this.setState({ pageSize: pageSize });
  };

  handleBulkDelete = () => {
    // Implement bulk delete logic here
  };

  handleBulkEdit = () => {
    // Implement bulk edit logic here
    this.setState({
      forBulkEditOpen: true,
    });
  };

  downloadSelectedRow = () => {
    const selectedData = this.generateBindData()
      .filter((store: any) =>
        this.state.selectedRowKeys.includes(store.storeId)
      )
      .map((store: any) => ({
        "Store Name": store.name,
        "Store Type": store.storeType,
        Items: store.items,
        "Store Address": store.storeAddress,
        // 'Store Address': store.addresses?.map((address: any) =>
        //   `${address.addressLineOne}, ${address.addressLineTwo}, ${address.city}, ${address.state}, ${address.country}, ${address.pincode}`
        // ).join(" | "),
      }));
    downloadExcel(selectedData, "Selected_Stores");
  };

  render() {
    const { searchData, currentPage, pageSize, isMobileView } = this.state;
    const startIndex = (currentPage - 1) * pageSize;
    const currentData = searchData.slice(startIndex, startIndex + pageSize);

    const additionalItems = [
      ...(isMobileView
        ? [
            <Menu.Item key="ManageUOM">
              <span
                onClick={() =>
                  this.setState({
                    isHistoryOpen: true,
                  })
                }
              >
                <ClockCircleOutlined /> View Stock History
              </span>
            </Menu.Item>,
          ]
        : []),
    ];

    const menu = <Menu className="ellipsisMenu">{additionalItems}</Menu>;

    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout className="layout">
          <div className="flexBox mb-flexFlow">
            <Breadcrumb
              className="breadcrumbs"
              items={[{ title: "Dashboard" }, { title: "Store Management" }]}
            />
            <div className="mt-10"></div>
          </div>
          <Content className="content-section">
            <div className="mb-10">
              <div className="flexBox">
                <div>
                  <div className="buttonStoreMobileViewRow">
                    <Button type="link" onClick={this.handleAddStore}>
                      <PlusOutlined /> Add Store
                    </Button>
                    <Button type="link" onClick={this.downloadSelectedRow}>
                      <DownloadOutlined /> Download
                    </Button>
                    {isMobileView ? (
                      <Dropdown overlay={menu} trigger={["click"]}>
                      <Button type="link" className="buttonAddItem buttonEllipsisMobile">
                        <EllipsisOutlined />
                      </Button>
                    </Dropdown>
                    ): ("")}
                     {!isMobileView ? (
                    <Button
                    type="link"
                    onClick={() =>
                      this.setState({
                        isHistoryOpen: true,
                      })
                    }
                    className="buttonCategories"
                  >
                    <ClockCircleOutlined /> View Stock History
                  </Button>
                  ): ("")}
                  </div>

                 
                </div>
                <div className="flexBox">
                  {this.state.showBulkActions &&
                  this.state.selectedRowKeys.length > 1 ? (
                    <div className="flexBox">
                      <Button type="link" onClick={this.handleBulkEdit}>
                        <EditOutlined /> Bulk Edit
                      </Button>
                      <Button type="link" danger>
                        <DeleteOutlined /> Bulk Delete
                      </Button>
                    </div>
                  ) : (
                    []
                  )}
                  <Input
                    placeholder="Search Stores"
                    className="searchStoreInputMob"
                    onChange={(e) => {
                      this.onSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <Table
              bordered
              columns={this.columns}
              dataSource={currentData}
              rowKey="storeId"
              pagination={false}
              scroll={{ x: 768 }}
              sticky
              rowSelection={this.rowSelection}
            />
            <Pagination
              total={searchData?.length}
              pageSize={pageSize}
              onChange={this.onPageChange}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ textAlign: "center", marginTop: 20 }}
            />
          </Content>
        </Layout>

        <Drawer
          title={this.state.onChangeTitle ? "Edit Store" : "Add a Store"}
          width={720}
          onClose={this.handleCloseDrawer}
          open={this.state.storeDrawerOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button onClick={this.handleCloseDrawer} className="buttomCancelMobView">Cancel</Button>
              <Button onClick={this.handleSubmit} type="primary">
                {this.state.onChangeTitle ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            requiredMark
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="storeName"
                  label="Store Name"
                  rules={[
                    { required: true, message: "Please enter store name" },
                    {
                      pattern: /^[A-Za-z0-9\s\-']{2,50}$/,
                      message:
                        "Store name: 2-50 chars, letters, numbers, spaces",
                    },
                  ]}
                >
                  <Input placeholder="Please enter store name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="addressLineOne"
                  label=" Store Address  1"
                  rules={[
                    { required: true, message: "Please enter Address Line 1" }
                  ]}
                >
                  <Input placeholder="Please enter address line 1" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="addressLineTwo"
                  label="Store Address 2"
                  rules={[
                    { required: true, message: "Please enter Address Line 2" } 
                  ]}
                >
                  <Input placeholder="Please enter address line 2" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="pincode"
                  label="Pin Code"
                  rules={[
                    { required: false, message: "Please enter Pin Code" },
                    {
                      pattern: /^[0-9]{6}$/,
                      message: "Pincode should be of 6 digits and in number",
                    },
                  ]}
                >
                  <Input placeholder="Please enter Pin Code" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    { required: true, message: "Please enter city" },
                    {
                      pattern: /^[A-Za-z\s-]{2,50}$/,
                      message: "Please enter a valid city name",
                    },
                  ]}
                >
                  <Input placeholder="Please enter city" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: true, message: "Please enter state" }]}
                >
                  <Select placeholder="Select a state" value={this.state.state}>
                    {this.state.stateList.map((state: any) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter country address" },
                  ]}
                >
                  <Select placeholder="Select a country" value={"India"}>
                    <Option value="India">India</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="storeType"
                  label="Store Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select at least one store type",
                    },
                  ]}
                >
                  <Checkbox.Group style={{ width: "100%", marginLeft: "0px" }}>
                    <Checkbox
                      value={1}
                      onChange={(elem: any) => {
                        let storeData = [...this.state.storeType]; // Create a copy of the array
                        if (elem.target.checked) {
                          if (!storeData.includes(1)) {
                            storeData.push(1);
                          }
                        } else {
                          storeData = storeData.filter(
                            (data: number) => data !== 1
                          ); // Filter out 1
                        }
                        this.setState({ storeType: storeData }, () => {});
                      }}
                    >
                      In Stock Store
                    </Checkbox>

                    <Checkbox
                      value={2}
                      disabled={this.state.isCheckboxDisabled}
                      onChange={(elem: any) => {
                        let storeData = [...this.state.storeType]; // Create a copy of the array
                        if (elem.target.checked) {
                          if (!storeData.includes(2)) {
                            storeData.push(2);
                          }
                        } else {
                          storeData = storeData.filter(
                            (data: number) => data !== 2
                          ); // Filter out 2
                        }
                        this.setState({ storeType: storeData }, () => {});
                      }}
                    >
                      Reject Store
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
        <History
          title="History"
          open={this.state.isHistoryOpen}
          onClose={this.closeHistoryDrawer}
          selectedItem={this.state.selectedStore}
        />
        <BulkEdit
          visible={this.state.forBulkEditOpen}
          onClose={() => this.setState({ forBulkEditOpen: false })}
          selectedItems={this.state.selectedRowKeys}
          title={"Stores Bulk Edit"}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  storesData: state.api.getStore?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getStoresState: state.api.getStore,
  addStoreState: state.api.addStore,
  editStoresState: state.api.editStore,
  deleteStoresState: state.api.deleteStore,
});

const mapDispatchToProps = (dispatch: any) => ({
  getStores: (payload: any) => dispatch(getStores(payload)),
  addStores: (payload: any) => dispatch(addStores(payload)),
  editStore: (payload: any) => dispatch(editStores(payload)),
  deleteStore: (payload: any) => dispatch(deleteStores(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoresManagement);
