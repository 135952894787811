import { Button, Col, Input, notification, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getAccountInfo, updateAccountInfo } from "../../../redux/actions/API/accountInfoAction";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";

type NotificationType = "success" | "info" | "warning" | "error";

interface IAccountInformationProps {
  getAccountInfoState: IDataResponse;
  updateAccountInfoState: IDataResponse;
  companyInfoData: any;
  getAccountInfo: Function;
  updateAccountInfo: Function;
}

const AccountInformation: React.FC<IAccountInformationProps> = ({
  companyInfoData,
  updateAccountInfo,
  getAccountInfo,
  getAccountInfoState,
  updateAccountInfoState
}) => {
  const prevPropsRef = useRef<IAccountInformationProps>();
  const [accountInfoData, setAccountInfoData] = useState<any>({
    companyName: "",
    email: "",
    contactNumber: "",
    companyWebsite: "",
    personName: "",
    personContactNo: "",
    personEmail: "",
    role: "",
  });
  const [loading, setLoading] = useState(false);

  const submitAccountInfo = () => {
    let accontInfoData = {
      companyName: accountInfoData.companyName,
      email: accountInfoData.email,
      contactNumber: accountInfoData.contactNumber,
      companyWebsite: accountInfoData.companyWebsite,
      personName: accountInfoData.personName,
      personContactNo: accountInfoData.personContactNo,
      personEmail: accountInfoData.personEmail,
      role: accountInfoData.role,
    };
    updateAccountInfo(accontInfoData);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  useEffect(() => {
    getAccountInfo()
  }, [])
  
  useEffect(() => {
    if (
      prevPropsRef?.current?.getAccountInfoState?.loading &&
      !getAccountInfoState?.loading
    ) {
      if (getAccountInfoState?.error?.length > 0) {
        openNotificationWithIcon(
          "error",
          "Failed to get account information"
        );
      } else {
        openNotificationWithIcon(
          "success",
          "Account get successfully."
        );
        setLoading(false);
      }
    }

    if (prevPropsRef?.current?.updateAccountInfoState?.loading && !updateAccountInfoState?.loading) {
      if (updateAccountInfoState?.error?.length > 0) {
        openNotificationWithIcon("error","Failed to update account information" );
        
      } else {
        openNotificationWithIcon(
          "success",
          "Account update successfully."
        );
        setLoading(false);
      }
    }

    prevPropsRef.current = { updateAccountInfoState, companyInfoData,getAccountInfo, updateAccountInfo, getAccountInfoState };
  }, [updateAccountInfo]);

  const renderComapnyName = () => {
    const label = "Company Name";
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>{label}</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter company name"
            value={companyInfoData?.companyName}
          />
        </Col>
      </>
    );
  };

  const renderComapnyEmail = () => {
    const label = "Company Email";
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>{label}</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter company email"
            value={companyInfoData?.email}
          />
        </Col>
      </>
    );
  };

  const renderCompanyContactNo = () => {
    const label = "Company Contact No";
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>{label}</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter company contact no"
            value={companyInfoData?.contactNumber}
          />
        </Col>
      </>
    );
  };

  const renderCompanyWebsite = () => {
    const label = "Company Website";
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>{label}</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter company website"
            value={companyInfoData?.companyWebsite}
          />
        </Col>
      </>
    );
  };

  const renderPersonName = () => {
    const label = "Person Name";
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>{label}</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter person name"
            value={companyInfoData?.personName}
          />
        </Col>
      </>
    );
  };

  const renderPersonContactNo = () => {
    const label = "Person Contact No";
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>{label}</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter person contact no."
            value={companyInfoData?.personContactNo}
          />
        </Col>
      </>
    );
  };

  const renderPersonEmail = () => {
    const label = "Person Email";
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>{label}</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter person email"
            value={companyInfoData?.personEmail}
          />
        </Col>
      </>
    );
  };

  const renderPersonRole = () => {
    const label = "Role";
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>{label}</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Enter role"
            value={companyInfoData?.role}
          />
        </Col>
      </>
    );
  };

  return (
    <>
    <Loader loading={loading}></Loader>
      <div className="documentMetaDataForm">
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
            {renderComapnyName()}
            {renderComapnyEmail()}
            {renderCompanyContactNo()}
            {renderCompanyWebsite()}
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
            {renderPersonName()}
            {renderPersonContactNo()}
            {renderPersonEmail()}
            {renderPersonRole()}
          </Row>
        </>
        <Row className="flexBox">
          <div className="flex-grow-1"></div>
          <Button onClick={submitAccountInfo} type="primary" className="mt-20">
            Save Changes
          </Button>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  getAccountInfoState: state.api?.getAccountInfo?.data,
  updateAccountInfoState: state.api?.updateAccountInfo?.data,
  companyInfoData: state.api.login.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateAccountInfo: (payload: any) => dispatch(updateAccountInfo(payload)),
 getAccountInfo: (payload: any) => dispatch(getAccountInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation);
