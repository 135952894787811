import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Drawer, Space } from "antd"; 
import logo from "../../Utility/images/logo.png";
import ease from "../../Utility/images/ease/svgfile/ease-primary.svg";
import "../../Utility/css/bootstrap.min.css";
import "../../Utility/css/flaticon.css";
import "../../Utility/css/menu.css";
import "../../Utility/css/magnific-popup.css";
import "../../Utility/css/owl.carousel.min.css";
import "../../Utility/css/owl.theme.default.min.css";
import "../../Utility/css/lunar.css";
import "../../Utility/css/animate.css";
import "../../Utility/css/pink-theme.css";
import "../../Utility/css/blue-theme.css";
import "../../Utility/css/responsive.css";
import "../../Utility/css/fonts/rubik.css";
import "../../Utility/css/fonts/jakarta.css";
import "../../Utility/css/fonts/inter.css";
import "./Header.css";

const Header: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const linkStyle = (path: string) => ({
    color: currentPath === path ? "#1780FB" : "black",
  });

  const scrollToFeatures = () => {
    closeDrawer();
    if (currentPath !== "/") {
      navigate("/");
      setTimeout(() => {
        const featuresSection = document.getElementById("features-13");
        if (featuresSection) {
          featuresSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      const featuresSection = document.getElementById("features-13");
      if (featuresSection) {
        featuresSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <header
        id="header"
        className="tra-menu navbar-dark white-scroll header-container"
      >
        <div className="header-wrapper">
          <div className="wsmobileheader clearfix">
            <Link to="/">
              <img className="logo-ease" src={ease} alt="sign" />
            </Link>
            {/* <span className="smllogo"><img src={logo} alt="mobile-logo" /></span> */}
            <a
              id="wsnavtoggle"
              className="wsanimated-arrow"
              onClick={showDrawer}
            >
              <span></span>
            </a>
          </div>
          <div className="wsmainfull menu clearfix">
            <div className="wsmainwp clearfix">
              <div className="desktoplogo">
                {/* <img src={ease} alt="sign" className="sign-logo" /> */}
                <Link to="/" className="logo-black">
                  <img src={logo} alt="logo" />
                </Link>
              </div>

              <div className="desktoplogo">
                <Link to="/" className="logo-white">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <nav className="wsmenu clearfix">
                <ul className="wsmenu-list nav-theme">
                  <li aria-haspopup="true">
                    <Link to="/" style={linkStyle("/")} className="h-link">
                      Home
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <Link
                      to="/about_us"
                      style={linkStyle("/about_us")}
                      className="h-link"
                    >
                      About Us
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <Space size="small" direction="vertical">
                      <Badge.Ribbon
                        text="Beta"
                        color="blue"
                        placement="end"
                        className="free-invoice-ribbon"
                      >
                        <div className="link-invoice">
                          <Link
                            to="/payment-invoice"
                            style={linkStyle("/payment-invoice")}
                            className="h-link mt-5"
                          >
                            Free Invoicing
                          </Link>
                        </div>
                      </Badge.Ribbon>
                    </Space>
                  </li>
                  {/* <li className="nl-simple" aria-haspopup="true"><a href="#features-13" className="h-link">Features</a></li>
									<li aria-haspopup="true" className="mg_link"><a href="#" className="h-link">Pages</a></li> */}
                  <li aria-haspopup="true">
                    <Link
                      to="/our-blogs"
                      style={linkStyle("/our-blogs")}
                      className="h-link"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <Link
                      to="/sign-in"
                      style={linkStyle("/login")}
                      className="h-link"
                    >
                      Login
                    </Link>
                  </li>
                  {/* <li className="nl-simple" aria-haspopup="true"><a href="pricing-1.html" className="h-link">Pricing</a></li>
									<li className="nl-simple" aria-haspopup="true"><a href="#faqs-3" className="h-link">FAQs</a></li> */}
                  {/* <li className="nl-simple reg-fst-link mobile-last-link" aria-haspopup="true">
										<Link to="/sign-in" className="h-link">Sign in</Link>
									</li>
									<li className="nl-simple" aria-haspopup="true">
										<Link to="/sign-up" className="btn r-04 btn--theme hover--tra-black last-link">Sign up</Link>
									</li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <Drawer
        placement="right"
        closable={false}
        onClose={closeDrawer}
        open={drawerVisible}
        width="150px"
        className="drawer-main"
      >
        {/* <div className="div-close">
					<CloseOutlined onClick={closeDrawer} className="image-close" />
				</div> */}
        <div className="text-bar">
          <Link to="/" style={linkStyle("/")} onClick={closeDrawer}>
            Home
          </Link>
        </div>
        <div className="text-bar">
          <a href="#" onClick={scrollToFeatures}>
            About
          </a>
        </div>
        <div className="text-bar">
          <Space size="small" direction="vertical">
            <Badge.Ribbon
              text="Beta"
              color="blue"
              placement="end"
              className="free-invoice-ribbon"
            >
              <div className="link-invoice">
                <Link
                  to="/payment-invoice"
                  style={linkStyle("/payment-invoice")}
                  className="h-link mt-5"
                >
                  Free Invoicing
                </Link>
              </div>
            </Badge.Ribbon>
          </Space>
        </div>
        <div className="text-bar">
          <Link
            to="/our-blogs"
            style={linkStyle("/our-blogs")}
            onClick={closeDrawer}
          >
            Blogs
          </Link>
        </div>
        <div className="text-bar">
          <Link
            to="/sign-in"
            style={linkStyle("/login")}
            onClick={closeDrawer}
          >
            Login
          </Link>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
