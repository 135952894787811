import * as type from "../../types/API/profile";

export function getProfileInfo(payload: any) {
  return {
    type: type.GET_PROFILE_INFO,
    payload: payload,
  };
}

export function updateProfileInfo(payload: any) {
  return {
    type: type.UPDATE_PROFILE_INFO,
    payload: payload,
  };
}
