import React, { useEffect, useRef, useState } from "react";
import type { MenuProps, TableProps } from "antd";
import { connect } from "react-redux";
import { Breadcrumb, Button, Dropdown, Input, Layout, notification, Pagination, Popconfirm, Popover, Space, Splitter, Switch, Table, Tag, Tooltip } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import "./Document.css";
import { setAdditionalDetailsData, setDocumentAdditionalChargesData, setDocumentCompanyData, setDocumentItemFormData, setDocumentItemGridData, setDocumentSignatureData, setDocumentTermsAndConditionsData, setDocumentType, setLogisticDetailsData } from "../../../redux/actions/UI/createDocument";
import EntitySelectionForm from "./EntitySelectionForm";
import { deleteDocument, discardDocument, editDocument, getDocumentById, getDocuments } from "../../../redux/actions/API/documents";
import noResult from "../../../Utility/images/noresult.png";
import { EditOutlined, DeleteOutlined, PlusOutlined, DownloadOutlined, FileOutlined, ArrowUpOutlined, ArrowDownOutlined, ArrowsAltOutlined, CloseOutlined, ShrinkOutlined, FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";
import { downloadExcel } from "../../../Utility/downloadExcel";
import { TableRowSelection } from "antd/es/table/interface";
import DocumentSlicer from "../previewDocument/DocumentSlicer";
import logo from "../../../Utility/images/ease/transparent/ease-primary.png";
import BulkEdit from "../bulkEdit/BulkEdit";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import FilterMenu from "../../../Home/Components/filter/FilterMenu";

type NotificationType = "success" | "info" | "warning" | "error";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface IDocumentProps {
  companyId: string;
  dataToPreview: any;
  companyName: string;
  documents: any;
  setDocumentCompanyData: Function;
  setDocumentType: Function;
  getDocuments: Function;
  editDocument: Function;
  getDocumentById: Function;
  discardDocument: Function;
  deleteDocument: Function;
  getDocumentsState: IDataResponse;
  discardDocumentState: IDataResponse;
  deleteDocumentState: IDataResponse;
}

interface DataType {
  key: string;
  documentNumber: string;
  documentType: string;
  companyName: string;
  buyerName: string;
  enquiryNumber: number;
  ocCreated: boolean;
  dealStatus: string;
  dealOwner: string;
  nextActionDate: string;
  createdAt: string;
}

const Documents: React.FC<IDocumentProps> = ({
  setDocumentCompanyData,
  setDocumentType,
  getDocuments,
  editDocument,
  documents,
  companyId,
  getDocumentsState,
  discardDocumentState,
  deleteDocumentState,
  getDocumentById,
  discardDocument,
  deleteDocument,
  dataToPreview,
  companyName,
}) => {
  const prevPropsRef = useRef<any>();
  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [docType, setDocType] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [breadcumpTitle, setBreadcumpTitle] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const url = window.location.href;
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [isSplitterActive, setIsSplitterActive] = useState<boolean>(false);
  const [splitterLoading, setSplitterLoading] = useState(false);
  const [forBulkEditOpen, setIsforBulkEditOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filteredDocs, setFilteredDocs] = useState(documents);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 896);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 414);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowBulkActions(true);
      if (isSplitterActive)
        getDocumentById({
          documentNumber: selectedRowKeys[0].toString(),
          companyId: Number(companyId),
        });
      //setSplitterLoading(true);
    } else {
      setShowBulkActions(false);
    }
  }, [selectedRowKeys, getDocumentById, companyId, isSplitterActive]);

  useEffect(() => {
    if (dataToPreview) {
      setSplitterLoading(false);
    }
  }, [dataToPreview]);

  useEffect(() => {
    setLoading(true);
    getDocuments({ companyId: Number(companyId) });
    if (url.includes("purchase")) {
      setBreadcumpTitle("Purchase");
    } else {
      setBreadcumpTitle("Sales");
    }
  }, [url]);

  useEffect(() => {
    if (
      prevPropsRef.current?.getDocumentsState?.loading &&
      !getDocumentsState?.loading
    ) {
      if (getDocumentsState?.error) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    if (
      prevPropsRef.current?.discardDocumentState?.loading &&
      !discardDocumentState?.loading
    ) {
      if (discardDocumentState?.error) {
        openNotificationWithIcon("error", discardDocumentState.error.message);
        setLoading(false);
      } else {
        getDocuments({ companyId: Number(companyId) });
        openNotificationWithIcon(
          "success",
          discardDocumentState?.data?.message
        );
      }
    }

    if (
      prevPropsRef.current?.deleteDocumentState?.loading &&
      !deleteDocumentState?.loading
    ) {
      if (deleteDocumentState?.error) {
        openNotificationWithIcon("error", deleteDocumentState.error.message);
        setLoading(false);
      } else {
        getDocuments({ companyId: Number(companyId) });
        openNotificationWithIcon("success", deleteDocumentState?.data?.message);
      }
    }
    prevPropsRef.current = {
      setDocumentCompanyData,
      deleteDocument,
      getDocumentsState,
      discardDocumentState,
      deleteDocumentState,
      setDocumentType,
      getDocuments,
      documents,
      companyId,
    };
  }, [
    getDocuments,
    deleteDocumentState,
    discardDocumentState,
    getDocumentsState,
    documents,
  ]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC",
    };
    return date.toLocaleString("en-GB", options);
  };

  const filteredDocuments = documents?.filter((doc: DataType) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      doc.documentNumber?.toLowerCase().includes(searchLower) ||
      doc.documentType?.toLowerCase().includes(searchLower) ||
      doc.buyerName?.toLowerCase().includes(searchLower) ||
      doc.enquiryNumber?.toString().includes(searchLower) ||
      (doc.ocCreated ? "Yes" : "No").toLowerCase().includes(searchLower) ||
      doc.dealStatus?.toLowerCase().includes(searchLower) ||
      doc.dealOwner?.toLowerCase().includes(searchLower) ||
      formatDate(doc.createdAt)?.toLowerCase().includes(searchLower) ||
      formatDate(doc.createdAt)?.toLowerCase().includes(searchLower)
    );
  });

  useEffect(() => {
    if (isSplitterActive && selectedRowKeys.length > 0) {
    }
  }, [selectedRowKeys, getDocumentById, companyId, isSplitterActive]);

  const navigateRows = (direction: "up" | "down") => {
    if (!filteredDocuments.length) return;

    let newIndex = currentRowIndex;
    if (direction === "up" && currentRowIndex > 0) newIndex -= 1;
    if (direction === "down" && currentRowIndex < filteredDocuments.length - 1)
      newIndex += 1;

    setCurrentRowIndex(newIndex);
    const newSelectedDocument = filteredDocuments[newIndex].documentNumber;
    setSelectedRowKeys([newSelectedDocument]);
    setSplitterLoading(true);
  };

  const closeSplitView = () => {
    setIsSplitterActive(false);
    setSelectedRowKeys([]);
    setLoading(false);
    setIsExpanded(false);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSplitViewToggle = (checked: boolean) => {
    setIsSplitterActive(checked);
    setSelectedRowKeys(checked ? [filteredDocuments[0].documentNumber] : []);
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setCurrentRowIndex(
      filteredDocuments.findIndex(
        (doc: any) => doc.documentNumber === newSelectedRowKeys[0]
      )
    );
    setSelectedRowKeys(newSelectedRowKeys);
    if (isSplitterActive) {
      setSplitterLoading(true);
    }
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: isSplitterActive ? "radio" : "checkbox",
  };

  const handleBulkDelete = () => {
    const updatedData = selectedRowKeys.filter(
      (row: any) => !selectedRowKeys.includes(row.id)
    );
    setSelectedRowKeys([]);
  };

  const handleBulkEdit = () => {
    setIsforBulkEditOpen(true);
    // setDataToHandle(selectedData);
  };

  const handleDiscard = (id: number) => {
    setLoading(true);
    discardDocument({ documentId: Number(id), companyId: Number(id) });
  };

  const handleDelete = (id: number) => {
    setLoading(true);
    deleteDocument({ documentId: Number(id) });
  };

  const handleUpdateDocument = (id: number) => {
    console.log("Edit Clicked", id);
    navigate("/update_document");
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Doc No.",
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: "200px",
      fixed: isMobileView ? undefined : "left",
      sorter: (a, b) => a.documentNumber.localeCompare(b.documentNumber),
      render: (docNumber: string, record: any) => (
        <span
          className="link"
          onClick={() => {
            navigate("/previewDocument?documentNumber=" + docNumber);
          }}
        >
          {docNumber}
        </span>
      ),
    },
    {
      title: "Doc Type",
      dataIndex: "documentType",
      key: "documentType",
      width: "200px",
      sorter: (a, b) => a.documentType.localeCompare(b.documentType),
    },
    {
      title: "Company",
      dataIndex: "buyerName",
      key: "buyerName",
      width: "200px",
      sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
    },
    {
      title: "Enquiry No.",
      dataIndex: "enquiryNumber",
      key: "enquiryNumber",
      width: "200px",
      sorter: (a: any, b: any) =>
        a.enquiryNumber.localeCompare(b.enquiryNumber),
    },
    {
      title: "OC Created",
      dataIndex: "ocCreated",
      key: "ocCreated",
      width: "200px",
      render: (ocCreated) => (ocCreated ? "Yes" : "No"),
      sorter: (a, b) => Number(a.ocCreated) - Number(b.ocCreated),
    },
    {
      title: "Deal Status",
      dataIndex: "dealStatus",
      key: "dealStatus",
      width: "200px",
      render: (docNumber: string, record: any) => (
        <>
          {record.status == 0 && (
            <Tag color="blue" className="mt-10">
              Draft
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Deal Owner",
      dataIndex: "dealOwner",
      width: "200px",
      key: "dealOwner",
    },
    {
      title: "Next Action Date",
      dataIndex: "nextActionDate",
      key: "nextActionDate",
      width: "200px",
      // render: (text) => formatDate(text),
      sorter: (a, b) =>
        new Date(a.nextActionDate).getTime() -
        new Date(b.nextActionDate).getTime(),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "200px",
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (text) => formatDate(text),
    },
    {
      title: "Action",
      key: "action",
      width: "115px",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Edit Document">
            <span
              className="actionIcons"
              onClick={() => handleUpdateDocument(record)}
            >
              <EditOutlined />
            </span>
          </Tooltip>
          <Tooltip title="Cancel Document">
            <Popconfirm
              title="Are you sure to Discard this document?"
              onConfirm={() => handleDiscard(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons">
                <CloseCircleOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const items: MenuProps["items"] =
    breadcumpTitle == "Sales"
      ? [
          {
            label: "Sales Enquiry",
            key: "salesEnquiry",
            icon: <FileOutlined />,
          },
          {
            label: "Sales Quotation",
            key: "salesQuotation",
            icon: <FileOutlined />,
          },
          {
            label: "Order Confirmation",
            key: "orderConfirmation",
            icon: <FileOutlined />,
          },
          {
            label: "Delivery Challan",
            key: "deliveryChallan",
            icon: <FileOutlined />,
          },
          { label: "Invoice", key: "invoice", icon: <FileOutlined /> },
          { label: "Sales Return", key: "salesReturn", icon: <FileOutlined /> },
        ]
      : [
          {
            label: "Purchase Order",
            key: "purchaseOrder",
            icon: <FileOutlined />,
          },
          {
            label: "Goods Receive Notes",
            key: "goodsReceiveNotes",
            icon: <FileOutlined />,
          },
          {
            label: "Quality Report",
            key: "qualityReport",
            icon: <FileOutlined />,
          },
          {
            label: "Purchase Invoice",
            key: "purchaseInvoice",
            icon: <FileOutlined />,
          },
          { label: "Debit Note", key: "debitNote", icon: <FileOutlined /> },
          { label: "Credit Note", key: "creditNote", icon: <FileOutlined /> },
          {
            label: "Purchase Return",
            key: "purchaseReturn",
            icon: <FileOutlined />,
          },
        ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const clickedItem: any = items.find((item: any) => item.key === e.key);
    setDocType(clickedItem?.key);
    setModalTitle(clickedItem?.label);
    setDocumentType(clickedItem?.label);
    setIsModalOpen(true);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const onSubmit = (companyData: any) => {
    setDocumentCompanyData(companyData);
    navigate("/create_document?documentType=" + docType);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const combinedDataSource = searchTerm ? filteredDocuments : filteredDocs;
  const renderDocumentTable = (): JSX.Element => {
    return (
      <>
        {filteredDocuments?.length > 0 ? (
          <Table
            className={isSplitterActive ? "splitterTable" : ""}
            columns={columns}
            rowKey="documentNumber"
            dataSource={combinedDataSource?.reverse()}
            bordered
            pagination={false}
            scroll={{ x: 1500 }}
            sticky
            rowSelection={rowSelection}
          />
        ) : (
          <div className="noData">
            <img src={noResult} alt="documentsData" />
            <p className="mt-20">
              You have not created any documents yet. Please create a document
            </p>
          </div>
        )}
      </>
    );
  };

  const downloadSelectedRow = () => {
    const selectedData = documents
      .filter((doc: any) =>
        selectedRowKeys.map(String).includes(String(doc.documentNumber))
      )
      .map((doc: any) => ({
        "Doc No.": doc?.documentNumber,
        "Doc Type": doc?.documentType,
        Company: doc?.buyerName,
        "Enquiry No.": doc?.enquiryNumber,
        "OC Created": doc?.OCDate ? "Yes" : "No",
        "Deal Status": "Deal Status",
        "Deal Owner": "Deal Owner",
        "Next Action Date": doc?.replyDate || "N/A",
        "Created Date": doc?.createdAt,
      }));
    downloadExcel(selectedData, "Selected_Documents");
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFilteredData = (filteredData: any[]) => {
    setFilteredDocs(filteredData);
  };

  return (
    <>
      <Loader loading={loading} />
      <Layout className="layout">
        <Breadcrumb
          items={[
            { title: "Dashboard" },
            { title: breadcumpTitle },
            { title: "Documents" },
          ]}
        />
        <Content className="content-section">
          <div className="mb-10">
            <div className="flexBox">
              <div>
                <Space wrap>
                  <Dropdown menu={menuProps} trigger={["click"]}>
                    <Button type="link">
                      <PlusOutlined /> Create Document
                    </Button>
                  </Dropdown>
                </Space>
                <Button
                  type="link"
                  onClick={downloadSelectedRow}
                  className="buttonAddItem"
                >
                  <DownloadOutlined /> Download
                </Button>
              </div>
              <div className="flexBox">
                {showBulkActions && selectedRowKeys.length > 1 ? (
                  <div className="flexBox">
                    <Button type="link" onClick={handleBulkEdit}>
                      <EditOutlined /> Bulk Edit
                    </Button>
                    <Button type="link" danger onClick={handleBulkDelete}>
                      <DeleteOutlined /> Bulk Delete
                    </Button>
                  </div>
                ) : (
                  []
                )}
                {!isMobileView ? (
                  <div>
                    <Switch
                      onChange={handleSplitViewToggle}
                      checked={isSplitterActive}
                      className="switch-split-screen"
                    />
                    <span className="text-split">Split View</span>
                  </div>
                ) : (
                  ""
                )}
                <div className="documentFilterSearchRow">
                  {!isMobileView ? (
                    <>
                      <Popover
                        overlayStyle={{ width: 300 }}
                        placement="bottom"
                        content={
                          <FilterMenu
                            filterType={"documentFilter"}
                            filterData={documents}
                            onApplyFilter={(filteredData) => {
                              handleFilteredData(filteredData);
                              setShowFilterMenu(false);
                            }}
                          />
                        }
                        trigger="click"
                        open={showFilterMenu}
                        onOpenChange={() => {
                          setShowFilterMenu(!showFilterMenu);
                        }}
                      >
                        <Button type="link" icon={<FilterOutlined />}>
                          {isMobileView ? "" : "Filter"}
                        </Button>
                      </Popover>
                      <Input
                        placeholder="Search..."
                        style={{ width: 200 }}
                        value={searchTerm}
                        onChange={handleSearch}
                        className="searchDocumentInput"
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        placeholder="Search..."
                        style={{ width: 200 }}
                        value={searchTerm}
                        onChange={handleSearch}
                        className="searchDocumentInput"
                      />
                      <Popover
                        overlayStyle={{ width: 300 }}
                        placement="bottom"
                        content={
                          <FilterMenu
                            filterType={"documentFilter"}
                            filterData={documents}
                            onApplyFilter={(filteredData) => {
                              handleFilteredData(filteredData);
                              setShowFilterMenu(false);
                            }}
                          />
                        }
                        trigger="click"
                        open={showFilterMenu}
                        onOpenChange={() => {
                          setShowFilterMenu(!showFilterMenu);
                        }}
                      >
                        <Button
                          type="link"
                          icon={<FilterOutlined />}
                          className="buttonDocumentFilter"
                        >
                          {isMobileView ? "" : "Filter"}
                        </Button>
                      </Popover>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isSplitterActive ? (
            <Splitter
              style={{ height: 200, boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
            >
              {!isExpanded && (
                <Splitter.Panel defaultSize="40%" min={"20%"} max={"70%"}>
                  {renderDocumentTable()}
                </Splitter.Panel>
              )}
              <Splitter.Panel>
                <div className="slicerContainer">
                  <Header className="header-split-view">
                    <div>
                      <ArrowUpOutlined
                        onClick={() => navigateRows("up")}
                        className="iconArrowUp"
                      />
                      <span className="spanIndex">
                        {currentRowIndex + 1} of {filteredDocuments.length}
                      </span>
                      <ArrowDownOutlined onClick={() => navigateRows("down")} />
                    </div>
                    <div>
                      {isExpanded ? (
                        <ShrinkOutlined onClick={toggleExpand} />
                      ) : (
                        <ArrowsAltOutlined onClick={toggleExpand} />
                      )}
                      <CloseOutlined onClick={() => closeSplitView()} />
                    </div>
                  </Header>
                  <div className="documentSlicerPanel">
                    {splitterLoading && (
                      <div className="slicerLoader">
                        <Loader loading={splitterLoading} />
                      </div>
                    )}
                    <DocumentSlicer
                      dataToPreview={dataToPreview}
                      companyName={companyName}
                      logo={logo}
                    />
                  </div>
                </div>
              </Splitter.Panel>
            </Splitter>
          ) : (
            renderDocumentTable()
          )}
          <BulkEdit
            visible={forBulkEditOpen}
            onClose={() => setIsforBulkEditOpen(false)}
            selectedItems={selectedRowKeys}
            title={"Documents Bulk Edit"}
          />
          {filteredDocuments?.length > 0 && (
            <Pagination
              total={combinedDataSource?.length}
              defaultPageSize={20}
              current={currentPage}
              pageSize={pageSize}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setPageSize(pageSize);
              }}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ textAlign: "center", marginTop: 20 }}
            />
          )}
          <EntitySelectionForm
            title={modalTitle}
            isModalOpen={isModalOpen}
            handleSubmit={onSubmit}
            handleCancel={handleCancel}
          />
        </Content>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  documents: state.api.getDocuments?.data?.reverse(),
  getDocumentsState: state.api.getDocuments,
  discardDocumentState: state.api.discardDocument,
  deleteDocumentState: state.api.deleteDocument,
  companyId: state.api.login?.data?.companyId,
  companyName: state.api.login.data.companyName,
  dataToPreview: state.api.getDocumentById?.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentCompanyData: (payload: any) =>
    dispatch(setDocumentCompanyData(payload)),
  setDocumentType: (payload: any) => dispatch(setDocumentType(payload)),
  getDocuments: (payload: any) => dispatch(getDocuments(payload)),
  getDocumentById: (payload: any) => dispatch(getDocumentById(payload)),
  editDocument: (payload: any) => dispatch(editDocument(payload)),
  discardDocument: (payload: any) => dispatch(discardDocument(payload)),
  deleteDocument: (payload: any) => dispatch(deleteDocument(payload)),
  setDocumentItemForm: (payload: any) =>
    dispatch(setDocumentItemFormData(payload)),
  setDocumentItemGridData: (payload: any) =>
    dispatch(setDocumentItemGridData(payload)),
  setDocumentTermsAndConditionsData: (payload: any) =>
    dispatch(setDocumentTermsAndConditionsData(payload)),
  setLogisticDetailsData: (payload: any) =>
    dispatch(setLogisticDetailsData(payload)),
  setAdditionalDetailsData: (payload: any) =>
    dispatch(setAdditionalDetailsData(payload)),
  setDocumentAdditionalChargesData: (payload: any) =>
    dispatch(setDocumentAdditionalChargesData(payload)),
  setDocumentSignatureData: (payload: any) =>
    dispatch(setDocumentSignatureData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
