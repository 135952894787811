import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import "../../Utility/css/pink-theme.css";
import "../../Utility/css/purple-theme.css";
import "../../Utility/css/violet-theme.css";
import "../../Utility/css/skyblue-theme.css";
import "../../Utility/css/magenta-theme.css";
import "../../Utility/css/crocus-theme.css";
import "../../Utility/css/red-theme.css";
import "../../Utility/css/green-theme.css";
import "../../Utility/css/blue-theme.css";
import "../../Utility/css/responsive.css";
import { Link } from "react-router-dom";
import "../../Utility/css/bootstrap.min.css";
import "../../Utility/css/flaticon.css";
import "../../Utility/css/menu.css";
import "../../Utility/css/magnific-popup.css";
import "../../Utility/css/owl.carousel.min.css";
import "../../Utility/css/owl.theme.default.min.css";
import "../../Utility/css/lunar.css";
import "../../Utility/css/animate.css";
import "../../Utility/css/pink-theme.css";
import "../../Utility/css/blue-theme.css";
import "../../Utility/css/responsive.css";
import "../../Utility/css/fonts/rubik.css";
import "../../Utility/css/fonts/jakarta.css";
import "../../Utility/css/fonts/inter.css";
import { blogPosts } from "../../Utility/data";
import Footer from "./Footer";
import { getBlogs } from "../../redux/actions/API/blogs";
import { connect } from "react-redux";
import "./Blogs.css";
import Loader from "../Loader/Loader";
import { notification, Select } from "antd";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
const { Option } = Select;
interface IBlogs {
  getBlogs: Function;
  blogsData: any;
  getBlogsState: IDataResponse;
}
type NotificationType = "success" | "info" | "warning" | "error";
const openNotificationWithIcon = (
  type: NotificationType,
  message: string
) => {
  notification.open({
    message: message,
    type: type,
    duration: 3,
  });
};

const Blogs: React.FC<IBlogs> = ({ getBlogs, blogsData, getBlogsState, }) => {
  const prevPropsRef = useRef<any>();
  const postsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const totalPages = Math.ceil(blogPosts.length / postsPerPage);
  const startIndex = (currentPage - 1) * postsPerPage + 1;
  const endIndex = startIndex + postsPerPage;
  const [selectedCategory, setSelectedCategory] = useState<string>("all");

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogs();
  }, []);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getBlogsState?.loading && !getBlogsState?.loading
    ) {
      console.log(getBlogsState.error);
      if (getBlogsState?.error?.length > 0) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      getBlogs,
      getBlogsState
    };
  }, [getBlogsState]);

  const truncateText = (text: any, maxLength = 150) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const filteredBlogs = selectedCategory === "all"
    ? blogsData
    : blogsData?.filter((blog: { category: string; }) => blog.category === selectedCategory);
  
  const renderBlogs = (blogs: any) => {
    return blogs.map((blogData: any, index: number) => {
      const updatedAtDate = new Date(blogData.updatedAt).toLocaleDateString();
      return (
        <div id="our-blogs" key={index} className="col-md-6 col-lg-4">
          <div className="blog-post mb-40 wow fadeInUp clearfix">
            <div className="blog-post-img mb-35">
              <img
                className="img-fluid r-16 mainBlogImage"
                src={blogData.imageURL}
                alt="blog-post-image"
              />
            </div>
            <div className="blog-post-txt">
              <span className={`post-tag`}>{blogData.header}</span>
              <h6 className="s-20 w-700">
              <Link to={`/blog_details/${blogData.id}/${encodeURIComponent(blogData.title).replace(/%20/g, '-').replace(/%7D/g, '')}`}>
                {blogData.title}
              </Link>
              </h6>
              <div className="blog-post-meta mt-20">
                <ul className="post-meta-list ico-10">
                  <li>
                    <p className="p-sm w-500">Author : {blogData.author}</p>
                  </li>
                  <li className="meta-list-divider">
                    <p>
                      <span className="flaticon-minus"></span>
                    </p>
                  </li>
                  <li>
                    <p className="p-sm">{updatedAtDate}</p>
                  </li>
                </ul>
              </div>
              <p>{truncateText(blogData.shortDesc)}</p>
              <Link to={`/blog_details/${blogData.id}/${encodeURIComponent(blogData.title).replace(/%20/g, '-').replace(/%7D/g, '')}`}>
                <div className="readMore">Read more...</div>
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <Header />
      <Loader loading={loading} />
      <section
        id="blog"
        className="pb-60 inner-page-hero blog-page-section blogList container-blog"
      >
        <div className="container">
          <div className="flexBox mb-10">
            <div></div> 
            <Select
              className="dropdown"
              defaultValue="all"
              style={{ width: 120 }}
              onChange={(value) => setSelectedCategory(value)}
            >
              <Option value="all">All</Option>
              <Option value="category">Category</Option>
            </Select>
          </div>
          <div className="posts-wrapper">
            {/* <div className="row">{renderBlogs(blogsData ? blogsData : [])}</div> */}
            <div className="row">{renderBlogs(filteredBlogs ?? [])}</div>
          </div>
        </div>
      </section>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  blogsData: state.api.getBlogs?.data?.reverse(),
  getBlogsState: state.api.getBlogs,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBlogs: (payload: any) => dispatch(getBlogs(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
