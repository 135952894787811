import { Avatar, Badge, Dropdown, Layout, theme, Menu } from "antd";
import { Footer, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { MenuProps } from "antd/es/menu/menu";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Utility/images/ease/transparent/ease-primary-white.png";
import {
  DashboardOutlined,
  SettingOutlined,
  IdcardOutlined,
  GoldOutlined,
  CommentOutlined,
  ShopOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  BellOutlined,
  CloseOutlined,
  UserOutlined,
  LinkOutlined,
  TruckOutlined,
  FileTextOutlined,
  AppstoreOutlined,
  BankOutlined,
  BookOutlined,
  DollarOutlined,
  LineChartOutlined,
  ProjectOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  ToolOutlined,
  BuildOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import BuyerSupplier from "./Companies";
import Dashboard from "../../Dashboard/Components/Dashboard";
import Settings from "../../Dashboard/Components/manage-user/Usermanagement"
import Usermanagement from "../../Dashboard/Components/manage-user/Usermanagement";
import Teammanagement from "../../Dashboard/Components/teams/Teammanagement";
import ProfileManagement from "../../Dashboard/Components/profileManagement/ProfileManagement";
import ManageBlogs from "../../Dashboard/Components/manage-blog/ManageBlogs";
import { connect } from "react-redux";
import { Persistor } from "../../redux/store";
import "./Main.css";
import Permissions from "../../Dashboard/Components/profileManagement/Permissions";
import ItemManagement from "../../Dashboard/Components/itemManagement/ItemManagement";
import StoresManagement from "../../Dashboard/Components/StoresManagement";
import CommentsCollections from "../../Dashboard/Components/blog-comments/CommentsCollections";
import Documents from "../../Dashboard/Components/Documents/Documents";
import CreateDocument from "../../Dashboard/Components/Documents/CreateDocument";
import PreviewDocument from "../../Dashboard/Components/previewDocument/PreviewDocument";
import { getNotifications } from "../../redux/actions/API/notifications";
import noNotification from "../../Utility/images/nonotification.png";
import Integrations from "../../Dashboard/Components/Integrations";
import Payments from "../../Dashboard/Components/Payment/Payments";
import Reports from "../../Dashboard/Components/Reports/Reports";
import Timeline from "../../Dashboard/Components/Timeline";
import DocsApproval from "../../Dashboard/Components/documents-approval/DocsApproval";
import BOM from "../../Dashboard/Components/production-section/BOM";
import Production from "../../Dashboard/Components/production-section/Production";
import NewWorkOrder from "../../Dashboard/Components/production-section/NewWorkOrder";
import CreateBillMaterial from "../../Dashboard/Components/production-section/CreateBillMaterial";

interface IMainInterface {
  loginData: any;
  notifications: any;
  getNotifications: Function;
}

const Main: React.FC<IMainInterface> = ({
  loginData,
  notifications,
  getNotifications,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<string[]>([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    getNotifications({ companyId: loginData.data.companyId });
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLogout = () => {
    Persistor.purge().then(() => {
      localStorage.removeItem("easemargin");
      window.location.href = "/login";
    });
  };

  const menuItems = notifications?.length
  ? notifications.map((notification: any) => ({
      key: notification.id,
      label: (
        <div className="item-notification">
          <span className="avatar-wrapper">
            <Avatar
              style={{ backgroundColor: "#0167ff" }}
              icon={<UserOutlined />}
            />
          </span>
          <div className="notification-user-action">
            <div className="notification-text">
              {notification.notification}
            </div>
          </div>
          <div className="dismiss-icon">
            <CloseOutlined />
          </div>
        </div>
      ),
    }))
  : [
      {
        key: "noNotifications",
        label: (
          <div className="noNotifications">
            <img src={noNotification} alt="No Notifications" />
          </div>
        ),
      },
    ];

  const handleOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => !isSideBarOpen.includes(key));
    setIsSideBarOpen(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleMenuClick = (data: any) => {
    // setSiderViewVisble(true)
    navigate("/" + data.key);
  };

  const renderComponents = (elem?: string) => {
    if (window.location.href.includes("buyer_supplier")) {
      return <BuyerSupplier />;
    }
    if (window.location.href.includes("dashboard") && checkAuth()) {
      console.log(window.location.href);
      return <Dashboard />;
    }
    if (window.location.href.includes("settings")) {
      console.log(window.location.href);
      return <Settings />;
    }
    if (window.location.href.includes("user_management")) {
      console.log(window.location.href);
      return <Usermanagement />;
    }
    if (window.location.href.includes("team_management")) {
      console.log(window.location.href);
      return <Teammanagement />;
    }
    if (window.location.href.includes("profile_management")) {
      console.log(window.location.href);
      return <ProfileManagement />;
    }
    if (window.location.href.includes("items")) {
      console.log(window.location.href);
      return <ItemManagement />;
    }
    if (window.location.href.includes("stores")) {
      console.log(window.location.href);
      return <StoresManagement />;
    }
    if (window.location.href.includes("manage_blogs")) {
      console.log(window.location.href);
      return <ManageBlogs />;
    }
    if (window.location.href.includes("comments")) {
      console.log(window.location.href);
      return <CommentsCollections />;
    }
    if (window.location.href.includes("permissions")) {
      console.log(window.location.href);
      return <Permissions />;
    }
    if (window.location.href.includes("create_document")) {
      console.log(window.location.href);
      return <CreateDocument />;
    }
    if (window.location.href.includes("update_document")) {
      console.log(window.location.href);
      return <CreateDocument />;
    }
    if (window.location.href.includes("/create_bill_of_material")) {
      console.log(window.location.href);
      return <CreateBillMaterial />;
    }
    if (window.location.href.includes("previewDocument")) {
      console.log(window.location.href);
      return <PreviewDocument />;
    }
    if (window.location.href.includes("sales_documents")) {
      console.log(window.location.href);
      return <Documents />;
    }
    if (window.location.href.includes("purchase_documents")) {
      console.log(window.location.href);
      return <Documents />;
    }
    if (window.location.href.includes("reports")) {
      console.log(window.location.href);
      return <Reports />;
    }
    if (window.location.href.includes("bill_of_material")) {
      console.log(window.location.href);
      return <BOM />;
    }
    if (window.location.href.includes("production")) {
      console.log(window.location.href);
      return <Production />;
    }
    if (window.location.href.includes("new_work_order")) {
      console.log(window.location.href);
      return <NewWorkOrder />;
    }
    if (window.location.href.includes("docs_approval")) {
      console.log(window.location.href);
      return <DocsApproval />;
    }
    if (window.location.href.includes("integrations")) {
      console.log(window.location.href);
      return <Integrations />;
    }
    if (window.location.href.includes("sales_transaction")) {
      console.log(window.location.href);
      return <Payments transactionType="Sales Transaction" />;
    }
    if (window.location.href.includes("purchase_transaction")) {
      console.log(window.location.href);
      return <Payments transactionType="Purchase Transaction" />;
    }
    if (window.location.href.includes("timeline")) {
      console.log(window.location.href);
      return <Timeline timelineType="Sales" />;
    }
    if (window.location.href.includes("purchase_timeline")) {
      console.log(window.location.href);
      return <Timeline timelineType="Purchase" />;
    }
  };

  const checkAuth = () => {
    return loginData?.data?.token ? true : false;
  };

  const items: MenuProps["items"] = [
    {
      label: <a href="#">Profile</a>,
      key: "0",
    },
    // {
    //   type: "divider",
    // },
    {
      label: <span onClick={handleLogout}>Logout</span>,
      key: "2",
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div>
      <Layout className="mainLayout">
        <Header
          className="header"
          style={{
            alignItems: "center",
            position: "sticky",
          }}
        >
          <div>
            <MenuOutlined className="hamburgur hide" onClick={toggleNav} />
            <img
              src={logo}
              className="logo"
              alt="Ease Margin"
              style={{ margin: "0px 0px 0px -30px", height: "30px" }}
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          </div>
          {/* <div>
            <Input
              className="global-search-input"
              placeholder="Global Search. Ex: Item/Item name"
            />
          </div> */}
          <div>
            <QuestionCircleOutlined style={{ color: "#FFFFFF" }} />
            <Dropdown
              menu={{
                items: menuItems,
                className: "menu-notification",
              }}
              trigger={["click"]}
              open={visible}
              onOpenChange={(flag) => setVisible(flag)}
            >
              <Badge
                count={notifications?.length}
                className="notificationCount"
              >
                <BellOutlined
                  style={{
                    color: "#FFFFFF",
                    marginLeft: "20px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              </Badge>
            </Dropdown>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <UserOutlined
                  style={{
                    marginRight: "10px",
                    marginLeft: "20px",
                    color: "#FFFFFF",
                  }}
                />
                <span style={{ color: "#FFFFFF" }} className="dhide">
                  {loginData.data.email}
                </span>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Layout>
          <Sider
            className={`sideNav ${isNavOpen ? "active" : ""}`}
            width={220}
            style={{
              background: colorBgContainer,
              minHeight: "100vh",
            }}
          >
            <Menu
              mode="inline"
              theme="light"
              defaultSelectedKeys={["1"]}
              openKeys={isSideBarOpen}
              onOpenChange={handleOpenChange}
              onClick={(elem: any) => {
                handleMenuClick(elem);
                setIsNavOpen(false);
              }}
              style={{
                height: "100%",
                borderRight: 0,
              }}
              items={[
                {
                  key: "dashboard",
                  icon: <DashboardOutlined />,
                  label: "Dashboard",
                },
                {
                  key: "buyer_supplier",
                  icon: <BankOutlined />,
                  label: "Companies",
                },
                {
                  key: "inventory_management",
                  icon: <GoldOutlined />,
                  label: "Inventory",
                  children: [
                    {
                      key: "items",
                      icon: <AppstoreOutlined />,
                      label: "Items",
                    },
                    {
                      key: "stores",
                      icon: <ShopOutlined />,
                      label: "Stores",
                    },
                  ],
                },
                {
                  key: "sales",
                  icon: <TruckOutlined />,
                  label: "Sales",
                  children: [
                    {
                      key: "sales_documents",
                      icon: <FileTextOutlined />,
                      label: "Documents",
                    },
                    {
                      key: "timeline",
                      icon: <ClockCircleOutlined />,
                      label: "Timeline",
                    },
                  ],
                },
                {
                  key: "purchase",
                  icon: <TruckOutlined />,
                  label: "Purchase",
                  children: [
                    {
                      key: "purchase_documents",
                      icon: <FileTextOutlined />,
                      label: "Documents",
                    },
                    {
                      key: "purchase_timeline",
                      icon: <ClockCircleOutlined />,
                      label: "Timeline",
                    },
                  ],
                },
                {
                  key: "docs_approval",
                  icon: <FileDoneOutlined />,
                  label: "Docs Approval",
                },
                {
                  key: "production_section",
                  icon: <ToolOutlined />,
                  label: "Production Section",
                  children: [
                    {
                      key: "bill_of_material",
                      icon: <FileTextOutlined />,
                      label: "Bill of material",
                    },
                    {
                      key: "production",
                      icon: <BuildOutlined />,
                      label: "Production",
                    },
                    {
                      key: "new_work_order",
                      icon: <PlusCircleOutlined />,
                      label: "New work order",
                    },
                  ],
                },
                {
                  key: "integrations",
                  icon: <LinkOutlined />,
                  label: "Integration",
                },
                {
                  key: "transaction",
                  icon: <DollarOutlined />,
                  label: "Transaction",
                  children: [
                    {
                      key: "sales_transaction",
                      icon: <ProjectOutlined />,
                      label: "Sales",
                    },
                    {
                      key: "purchase_transaction",
                      icon: <TruckOutlined />,
                      label: "Purchase",
                    },
                  ],
                },
                {
                  key: "reports",
                  icon: <LineChartOutlined />,
                  label: "Reports",
                },
                {
                  key: "settings",
                  icon: <SettingOutlined />,
                  label: "Settings",
                  children: [
                    {
                      key: "profile_management",
                      icon: <IdcardOutlined />,
                      label: "Profile",
                    },
                    {
                      key: "user_management",
                      icon: <UserOutlined />,
                      label: "Users",
                    },
                  ],
                },
                { key: "manage_blogs", icon: <BookOutlined />, label: "Blogs" },
                {
                  key: "comments",
                  icon: <CommentOutlined />,
                  label: "Comments",
                },
              ]}
            />
          </Sider>
          {renderComponents()}
        </Layout>
        <Footer
          style={{
            textAlign: "left", // Align content to the left
            position: "sticky",
            bottom: 0,
            backgroundColor: "#f0f2f5", // Change to match your theme
          }}
        >
          &copy; {currentYear} All rights reserved. Designed and developed by
          Datronix Digital Pvt. Ltd.
        </Footer>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginData: state.api.login,
  notifications: state.api.getNotifications?.data?.reverse(),
});

const mapDispatchToProps = (dispatch: any) => ({
  getNotifications: (payload: any) => dispatch(getNotifications(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
