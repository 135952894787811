import * as type from "../../types/API/contactUsType";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getContactUsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_CONTACT_US:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.GET_CONTACT_US_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addContactUsReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.ADD_CONTACT_US:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_CONTACT_US_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_CONTACT_US_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editContactUsReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.EDIT_CONTACT_US:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_CONTACT_US_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_CONTACT_US_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteContactUsReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.DELETE_CONTACT_US:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_CONTACT_US_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_CONTACT_US_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};