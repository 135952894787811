import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Drawer, Form, Input, notification, Radio, Row, Select, Space, Tag } from "antd";
import "./companyForm.css";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { addBuyerSupplier, getBuyerSupplier, editBuyerSupplier, } from "../../redux/actions/API/buyerSupplier";
import Loader from "../Loader/Loader";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import { AppConstants } from "../../Appconstants";
import { stateData } from "../../Dashboard/utility/StateData";

const { Option } = Select;
 
interface DrawerProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (data: any) => void;
  onEdit: (data: any) => void;
  dataToHandle: any;
  isDisable: boolean;
  isEditMode: boolean;
  addBuyerSupplier: Function;
  editBuyerSupplier: Function;
  companyId: number;
  addBuyerSupplierState: IDataResponse;
  getBuyerSupplier: Function;
  getBuyerSupplierState: IDataResponse;
  editBuyerSupplierState: IDataResponse; 
}

type NotificationType = "success" | "info" | "warning" | "error";

const CompanyForm: React.FC<DrawerProps> = ({
  visible,
  onClose,
  onEdit,
  dataToHandle,
  isDisable,
  isEditMode,
  addBuyerSupplier,
  editBuyerSupplier,
  companyId,
  addBuyerSupplierState,
  getBuyerSupplier,
  getBuyerSupplierState,
  editBuyerSupplierState,
}) => {
  const prevPropsRef = useRef<any>();
  const [form] = Form.useForm(); 
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [title, setTitle] = useState("Add Company");
  const [addresses, setAddresses] = useState<any[]>([]);
  const [showLink, setshowLink] = useState(true);
  const [showBtns, setshowBtns] = useState(true);
  const [showAddress, setShowAddress] = useState(false); 
  const [defaultAddress, setDefaultAddress] = useState<number | null>(0);
  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState(stateData["India"]);
  const [selectedState, setSelectedState] = useState<string | undefined>();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    if (dataToHandle) {
      setAddresses(dataToHandle.addresses);
      form.setFieldsValue(dataToHandle);
      if (isDisable) {
        setTitle("View Company");
        setshowLink(false);
        setShowAddress(false);
        setshowBtns(false);
      }
      if (isEditMode && isEdit) {
        setAddresses(dataToHandle.addresses);
        setIsEdit(true);
        setTitle("Edit Company");
        setshowLink(true);
        setshowBtns(true);
      }
    } else {
      setIsEdit(false);
      setTitle("Add Company");
      setshowLink(true);
    }
  }, [isEditMode, isDisable, dataToHandle]);

  useEffect(() => {
    setLoading(true);
    getBuyerSupplier({companyId: companyId});
  }, []);

  useEffect(() => {
    if (prevPropsRef?.current?.getBuyerSupplierState?.loading && !getBuyerSupplierState?.loading
    ) {
      if (getBuyerSupplierState?.error ) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }

    if (prevPropsRef?.current?.addBuyerSupplierState?.loading && !addBuyerSupplierState?.loading
    ) {
      if (addBuyerSupplierState?.error) {
        openNotificationWithIcon("error", addBuyerSupplierState.error.message);
        setLoading(false);
      } else {
        getBuyerSupplier({companyId: companyId});
        openNotificationWithIcon("success", addBuyerSupplierState?.data?.message);
        setAddresses([]);
        form.resetFields();
      }
    }

    if (prevPropsRef?.current?.editBuyerSupplierState?.loading && !editBuyerSupplierState?.loading) {
      if (editBuyerSupplierState?.error) {
        openNotificationWithIcon("error", editBuyerSupplierState?.error?.message);
        setLoading(false);
      } else {
        getBuyerSupplier({ companyId: Number(companyId) });
        openNotificationWithIcon(
          "success",
          editBuyerSupplierState?.data?.message
        );
      }
    }

    prevPropsRef.current = {
      addBuyerSupplier,
      editBuyerSupplier,
      addBuyerSupplierState,
      getBuyerSupplier,
      getBuyerSupplierState,
      editBuyerSupplierState,
    };
  }, [addBuyerSupplierState, getBuyerSupplierState, editBuyerSupplierState]);

  const clearAddressFields = () => {
    form.resetFields(["addresses", "pincode", "city", "state", "country", "addressType"]);
  };  

  const handleStateChange = (value: string) => {
    setSelectedState(value);
  };

  const handleCancel = () => {
    onClose();
    setshowBtns(false);
    setAddresses([]);
    setShowAddress(false);
    form.resetFields();
  };

  const handleDeleteAddress = (index: number) => {
    const updatedAddresses = addresses.filter((currEl, i) => i !== index);
    setAddresses(updatedAddresses);
    if (updatedAddresses.length === 0) {
      form.setFieldsValue({ addressType: undefined });
    }
  };

  const onFinish = (values: any) => {
    if (showAddress) {
      form
        .validateFields([
          "addresses",
          "addressType",
          "pincode",
          "city",
          "state",
          "country",
        ])
        .then(() => {
          handleAddMoreAddress();
        })
        .catch((err) => {
          setLoading(false);
          console.error("Validation failed:", err);
        });
    } else {
      if (isEdit) {
        onEdit({ ...dataToHandle, ...values });
      } else {
        const payload = {
          companyId: companyId,
          companyEmail: values.companyEmail,
          companyName: values.companyName,
          companyType: values.companyType,
          email: values.email,
          gstNumber: values.GSTNumber,
          gstType: values.GSTType,
          name: values.name,
          phone: values.phone,
          ip_address: "127.0.0.1",
          addresses: addresses,
        };
        addBuyerSupplier(payload);
        setAddresses([]);
        form.resetFields();
        setLoading(true);
      }

      form.setFieldsValue({ addressType: undefined });
      setShowAddress(false);
      onClose();
    }
  };

  const handleAddMoreAddress = () => {
    form
      .validateFields(["addresses", "pincode", "city", "state", "country", "addressType"])
      .then(() => {
        const currentValues = form.getFieldsValue();
  
        setAddresses((prevAddresses) => [
          ...prevAddresses,
          {
            id: new Date().getTime(),
            addresses: currentValues.addresses,
            pincode: currentValues.pincode,
            city: currentValues.city,
            state: currentValues.state,
            country: currentValues.country,
            addressType: currentValues.addressType,
          },
        ]);
  
        clearAddressFields();  
        setShowAddress(false);
      })
      .catch((error) => {
        console.error("Address validation failed:", error);
      });
  };
  
  const handleSetDefaultAddress = (index: number) => {
    setDefaultAddress(index);
  };
  
  const handleEditAddress = (index: number) => {
    const addressToEdit = addresses[index];
    console.log("Editing Address:", addressToEdit);
    setShowAddress(true);
    form.setFieldsValue({
      addresses: addressToEdit.addresses,
      pincode: addressToEdit.pincode,
      city: addressToEdit.city,
      state: addressToEdit.state,
      country: addressToEdit.country,
      addressType: addressToEdit.addressType,
    });

    console.log("Form Values After Setting:", form.getFieldsValue());

    setIsEdit(true);
    setEditIndex(index);
  };

  const handleSaveAddress = () => {
    console.log(form.getFieldsValue());
    form
      .validateFields()
      .then(() => {
        const currentValues = form.getFieldsValue();
        console.log(currentValues);

        if (
          !currentValues.addresses ||
          !currentValues.pincode ||
          !currentValues.city ||
          !currentValues.state ||
          !currentValues.country ||
          !currentValues.addressType
        ) {
          return;
        }

        if (isEdit) {
          const updatedAddresses = addresses.map((address, index) =>
            index === editIndex
              ? {
                  addresses: currentValues.addresses,
                  pincode: currentValues.pincode,
                  city: currentValues.city,
                  state: currentValues.state,
                  country: currentValues.country,
                  addressType: currentValues.addressType,
                }
              : address
          );
          setAddresses(updatedAddresses);
        } else {
          setAddresses([
            ...addresses,
            {
              addresses: currentValues.addresses,
              pincode: currentValues.pincode,
              city: currentValues.city,
              state: currentValues.state,
              country: currentValues.country,
              addressType: currentValues.addressType,
            },
          ]);
        }
        clearAddressFields()
        setShowAddress(false);
      })
      .catch(() => {});
  };

  return (
    <>
      <Loader loading={loading} />
      <Drawer
        placement="right"
        title={title}
        onClose={handleCancel}
        open={visible}
        width={"1000px"}
        extra={
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                setLoading(true);
                form.submit();
              }}
              disabled={!(addresses?.length > 0)}
            >
              {isEdit ? "Update" : "Submit"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            country: "India",
          }}
        >
          <div className="flexBox">
            <div className="formLabel">Person Contact Details</div>
            <div></div>
          </div>
          <div className="form-label">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Please enter a name" }]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please enter an email" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your phone number",
                    },
                    {
                      pattern: /^(?:\+91|0)?[6-9]\d{9}$/,
                      message: "Please enter a valid Indian phone number",
                    },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="flexBox mt-10">
            <div className="formLabel">Company Details</div>
            <div></div>
          </div>
          <div className="form-label">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="companyName"
                  label="Company Name"
                  rules={[{ required: true, message: "Please enter a company name" }]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="companyEmail"
                  label="Company Email"
                  rules={[
                    { required: true, message: "Please enter a company email" },
                    {
                      type: "email",
                      message: "Please enter a valid company email address",
                    },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Form.Item
                name="companyType"
                label="Company Type"
                rules={[{ required: true, message: "Please enter a company type" }]}
              >
                <Radio.Group style={{ width: "100%" }} disabled={isDisable}>
                  <Row>
                    <Col span={8}>
                      <Radio value={1}>Buyer</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value={2}>Supplier</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value={3}>Both</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="GSTNumber"
                  label="GST Number"
                  rules={[ 
                    { 
                      pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/, 
                      message: "Please enter a valid GST number" 
                    }
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="GSTType"
                  label="GST Type"
                  rules={[{ required: false, message: "Please select a GST type" }]}
                >
                  <Select disabled={isDisable} placeholder="Select GST Type">
                    <Option value={1}>Regular</Option>
                    <Option value={2}>Irregular</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="mt-10 mb-10">
            <div className="flexBox">
              {showAddress && <div className="formLabel">Addresses</div>}
              <div></div>
              {showLink && (
                <div
                  className="addMore"
                  onClick={() => {
                    setShowAddress(true);
                    clearAddressFields();
                  }}
                >
                  {addresses.length > 0
                    ? "+ Add more address"
                    : "+ Add Address"}
                </div>
              )}
            </div>
          </div>
          {addresses?.map((address: any, index: number) => (
            <div className="addressList" key={index}>
              <div className="flexBox">
                <div className="title">
                  {Number(address.addressType) ==
                  AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                    ? AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_NAME
                    : AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_NAME}
                </div>
                <Tag
                  key={index}
                  className={index === 0 ? "defaultType" : "setAsdefaultType"}
                  color={index === 0 ? "green" : "blue"}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSetDefaultAddress(index)}
                >
                  {index === 0 ? "Default" : "Set as Default"}
                </Tag>
              </div>
              <div className="addressBody flexBox mt-2">
                <div>
                  {`${address.addresses}, ${address.city}, ${address.state}, ${address.pincode}, ${address.country}`}
                </div>
                <div className="flexBox">
                  {showBtns && (
                    <>
                      <EditOutlined
                        style={{ cursor: "pointer", marginRight: "10px" }}
                        onClick={() => handleEditAddress(index)}
                      />
                      <DeleteOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteAddress(index)}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}

          {showAddress && (
            <div className="form-label">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="addresses"
                    label="Address"
                    rules={[{ required: true, message: "Please enter an address" }]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="addressType"
                    label="Address Type"
                    rules={[{ required: true, message: "Please select address type" }]}
                  >
                    <Radio.Group
                      disabled={isDisable}
                      style={{ width: "100%", marginLeft: "px" }}
                    >
                      <Row>
                        <Col span={8} xs={12} sm={12}>
                          <Radio value={1}>Delivery Address</Radio>
                        </Col>
                        <Col span={8} xs={12} sm={12}>
                          <Radio value={2}>Billing Address</Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="pincode"
                    label="Pin Code"
                    rules={[{ required: false, message: "Please enter a pincode" },
                      {
                        pattern: /^[1-9][0-9]{5}$/,
                        message:
                          "Please enter a valid 6-digit pincode starting with a non-zero digit",
                      },
                    ]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[{ required: true, message: "Please enter a city" }]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[{ required: true, message: "Please select a country" }]}
                  >
                    <Select placeholder="Select a country">
                      <Option value="India">India</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[{ required: true, message: "Please enter a state" }]}
                  >
                    <Select
                      placeholder="Select a state"
                      value={selectedState}
                      onChange={handleStateChange}
                    >
                      {stateList.map((state) => (
                        <Option key={state} value={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item>
                  <Button type="link" onClick={handleSaveAddress}>
                    Save Address
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    onClick={() => {
                      clearAddressFields();
                      setShowAddress(false);
                    }}
                  >
                    Discard
                  </Button>
                </Form.Item>
              </div>
            </div>
          )}
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  addBuyerSupplierState: state.api.addBuyerSupplier,
  getBuyerSupplierState: state.api.getBuyerSupplier,
  editBuyerSupplierState: state.api.editBuyerSupplier,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  addBuyerSupplier: (payload: any) => dispatch(addBuyerSupplier(payload)),
  getBuyerSupplier: (payload: any) => dispatch(getBuyerSupplier(payload)),
  editBuyerSupplier: (payload: any) => dispatch(editBuyerSupplier(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
