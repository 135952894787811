export const GET_CONTACT_US = "GET_CONTACT_US";
export const GET_CONTACT_US_SUCCESS = "GET_CONTACT_US_SUCCESS";
export const GET_CONTACT_US_FAILED = "GET_CONTACT_US_FAILED";

export const ADD_CONTACT_US = "ADD_CONTACT_US";
export const ADD_CONTACT_US_SUCCESS = "ADD_CONTACT_US_SUCCESS";
export const ADD_CONTACT_US_FAILED = "ADD_CONTACT_US_FAILED";

export const EDIT_CONTACT_US = "EDIT_CONTACT_US";
export const EDIT_CONTACT_US_SUCCESS = "EDIT_CONTACT_US_SUCCESS";
export const EDIT_CONTACT_US_FAILED = "EDIT_CONTACT_US_FAILED";

export const DELETE_CONTACT_US = "DELETE_CONTACT_US";
export const DELETE_CONTACT_US_SUCCESS = "DELETE_CONTACT_US_SUCCESS";
export const DELETE_CONTACT_US_FAILED = "DELETE_CONTACT_US_FAILED";