import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./AboutUs.css";
import { Card, Col, Row } from "antd";

const AboutUs: React.FC = () => {
  return (
    <>
      <Header />
      <section className="about-container">
        <h2 className="heading-aboutus">About Us</h2>
        <div className="text-aboutus">
          Your profit margin starts with Ease Margin - Created to empower
          India's manufacturing sector with advanced but simple manufacturing
          management software.
        </div>
        <div className="aboutus-block-container">
          <div className="block-container-one">
            <div className="mission-container">
              <h5 className="heading-brief-text">Mission Statement</h5>
              <div className="text-about-mission">
                Build an easiest software for SME manufacturers to reduce
                repetitive tasks by automating workflows and streamlining
                day-to-day operations.
              </div>
            </div>

            {/* <div className="vision-container">
              <h5>Vision Statement</h5>
            </div> */}
          </div>

          <div className="block-container-two">
            <div className="target-container">
              <h5>
                Target Market <br />
                Summary
              </h5>
              <div className="text-target">
                Ease Margin is tailored to empower India’s Small and
                Medium-Sized Enterprises (SMEs) in the manufacturing sector,
                aiming to streamline and optimize their daily operations such as
                inventory management,production tracking and sales and purchase
                activity. Growing with technology is a necessity and our
                manufacturers are a strong pillar of India's growing economy.{" "}
                <br />
                Ease Margin helps manufacturers across the sectors ranging from
                consumer goods,pharmaceutical,chemicals,foods &
                beverages,textile and engineering.Due to inadequate manual
                method of managing the daily operations there has always been a
                possibility of errors and mistakes.Which in returns have
                hindered the growth at which the business should actually
                grow.Ease Margin solution helps to define your own profit margin
                by streamlining all the daily operations through a simple yet a
                powerful software.
              </div>
            </div>

            <div className="block-container-three">
              <div className="core-container">
                <h5>Core Values</h5>
                <div className="core-sub-container">
                  <Row gutter={[24, 24]}>
                    <Col xs={12} sm={12} lg={6}>
                      <Card className="card-core-text">
                        <div className="core-text">Innovation</div>
                      </Card>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <Card className="card-core-text">
                        <div className="core-text">Collaboration</div>
                      </Card>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <Card className="card-core-text">
                        <div className="core-text">Ownership</div>
                      </Card>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <Card className="card-core-text">
                        <div className="core-text">Transparency</div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="breif-container">
                <h5 className="heading-brief-text">Brief Company History</h5>
                <div className="brief-text mt-10">
                  Saif,Harish and Rahul founded the Ease Margin with a purpose
                  of solving the challenges faced by our manufacturers in their
                  daily operations.Often due to manual processes our
                  manufacturers lose their productivity and hence overall profit
                  and scalability of their businesses. <br />
                  Being involved in the manufacturing sector for a very long
                  time we figured out that it is a necessity where we need to
                  empower manufactures with smart management of their business
                  with best in class software and contribute to India's
                  self-reliant movement.
                  <br />
                  We have started our journey with a mission to reach each SME
                  in India and provide software which is affordable and
                  manufacturer friendly.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-10">
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
