import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Layout,
  notification,
  Popconfirm,
  Row,
  Space,
  Pagination,
  Tooltip,
  List,
  Select,
} from "antd";
import {
  CloseOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useMemo,
} from "react";
import { connect } from "react-redux";
import {
  addBlog,
  deleteBlog,
  editBlog,
  getBlogs,
} from "../../../redux/actions/API/blogs";
import { uploadImage } from "../../../redux/actions/API/imageUpload";
import "./ManageBlogs.css";
import Loader from "../../../Home/Loader/Loader";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import JoditEditor, { Jodit } from "jodit-react";
import DOMPurify from "dompurify";

interface IManageBlogsProps {
  getBlogs: Function;
  blogsData: any;
  userId: number;
  getBlogsState: IDataResponse;
  addBlogs: Function;
  addBlogsState: IDataResponse;
  editBlog: Function;
  editBlogState: IDataResponse;
  deleteBlog: Function;
  deleteBlogState: IDataResponse;
  blogId: number;
  uploadImage: Function;
  uploadBlogImageState: any;
  placeholder?: string;
}
type NotificationType = "success" | "info" | "warning" | "error";

const ManageBlogs: React.FC<IManageBlogsProps> = ({
  getBlogs,
  blogsData,
  userId,
  getBlogsState,
  addBlogs,
  addBlogsState,
  editBlog,
  editBlogState,
  deleteBlog,
  deleteBlogState,
  blogId,
  uploadImage,
  uploadBlogImageState,
  placeholder = "",
}) => {
  const prevPropsRef = useRef<any>();
  const [form] = Form.useForm();
  const editorRef = useRef(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [addBlogsOpen, setAddBlogsOpen] = useState(false);
  const [blogTitle, setBlogTitle] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [author, setAuthor] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [stateBlogData, setStateBlogData] = useState(blogsData);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBlogId, setCurrentBlogId] = useState<number | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [blogClickStatus, setBlogClickStatus] = useState("");
  const [uploadFileSize, setUploadFileSize] = useState(0);
  const [isInvalidFileType, setIsInvalidFileType] = useState(false);
  const [blogDescError, setBlogDescError] = useState("");
  const [fileError, setFileError] = useState("");
  const [urlTitle, setUrlTitle] = useState("");
  const [category, setCategory] = useState("");

  const config = useMemo(
    () => ({
      readonly: false,  
      placeholder: placeholder || "",
    }),
    [placeholder]
  );

  useEffect(() => {
    getBlogs();
  }, []);

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue({
        blogTitle: blogTitle,
        shortDesc: shortDesc,
        urlTitle: urlTitle,
        category: category,
        blogDescription: blogDescription,
        imageURL: imageURL,
        author: author,
        userId: userId,
      });
    }
  }, [isEditing]);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getBlogsState?.loading &&
      !getBlogsState?.loading
    ) {
      if (getBlogsState?.error?.length > 0) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }

    if (
      prevPropsRef?.current?.addBlogsState?.loading &&
      !addBlogsState?.loading
    ) {
      if (addBlogsState?.error?.length > 0) {
        openNotificationWithIcon("error", "Failed to add blog");
      } else {
        getBlogs();
        openNotificationWithIcon("success", "Blog added successfully");
        clearForm();
        setAddBlogsOpen(false);
      }
    }

    if (
      prevPropsRef?.current?.uploadBlogImageState?.loading &&
      !uploadBlogImageState?.loading
    ) {
      if (uploadBlogImageState?.error?.length > 0) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setImageURL(uploadBlogImageState.imageUrl);
        if (blogClickStatus === "publish") {
          addBlogs({
            title: blogTitle,
            shortDesc: shortDesc,
            content: blogDescription,
            urlTitle: urlTitle,
            category: category,
            userId: userId,
            author: author,
            imageURL: uploadBlogImageState.imageUrl,
          });
        } else {
          editBlog({
            blogId: currentBlogId,
            title: blogTitle,
            shortDesc: shortDesc,
            content: blogDescription,
            urlTitle: urlTitle,
            category: category,
            userId: userId,
            author: author,
            imageURL: uploadBlogImageState.imageUrl,
          });
        }
      }
    }

    if (
      prevPropsRef?.current?.deleteBlogState?.loading &&
      !deleteBlogState?.loading
    ) {
      if (deleteBlogState?.error?.length > 0) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        getBlogs();
        openNotificationWithIcon("success", "Blog deleted successfully");
      }
    }

    if (
      prevPropsRef?.current?.editBlogState?.loading &&
      !editBlogState?.loading
    ) {
      if (editBlogState?.error?.length > 0) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
      } else {
        getBlogs();
        openNotificationWithIcon("success", "Blog updated successfully");
        clearForm();
        setAddBlogsOpen(false);
      }
    }
    prevPropsRef.current = {
      uploadImage,
      getBlogs,
      blogsData,
      userId,
      getBlogsState,
      addBlogs,
      addBlogsState,
      editBlog,
      editBlogState,
      deleteBlog,
      deleteBlogState,
      blogId,
      uploadBlogImageState,
    };
  }, [
    getBlogsState,
    addBlogsState,
    deleteBlogState,
    editBlogState,
    uploadBlogImageState,
  ]);

  useEffect(() => {
    setStateBlogData(blogsData);
  }, [blogsData]);

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setPreviewUrl(null);
    }
  }, [file]);

  const addEditBlogCall = () => {
    if (blogClickStatus === "publish") {
      addBlogs({
        title: blogTitle,
        shortDesc: shortDesc,
        content: blogDescription,
        urlTitle: urlTitle,
        category: category,
        userId: userId,
        author: author,
        imageURL: imageURL,
      });
    } else {
      editBlog({
        blogId: currentBlogId,
        title: blogTitle,
        shortDesc: shortDesc,
        content: blogDescription,
        urlTitle: urlTitle,
        category: category,
        userId: userId,
        author: author,
        imageURL: imageURL,
      });
    }
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  const onEditBlog = (blog: any, userId: number) => {
    console.log(blog)
    setAddBlogsOpen(true);
    setCurrentBlogId(blog.id);
    setBlogTitle(blog.title);
    setShortDesc(blog.shortDesc);
    setUrlTitle(blog.urlTitle);
    setCategory(blog.category);
    setBlogDescription(blog.content);
    setAuthor(blog.author);
    setImageURL(blog.imageURL);
    setIsEditing(true);
  };

  const onDeleteBlog = (blogId: number) => {
    deleteBlog({ id: blogId });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFileError("");
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const fileSize = selectedFile.size / 1024;
      setUploadFileSize(fileSize);

      if (!["image/png", "image/jpeg"].includes(fileType)) {
        setIsInvalidFileType(true);
        setFile(null);
        setFileError("Invalid file type");
        return;
      }

      if (fileSize > 2048) {
        setFile(selectedFile);
        setFileError("Maximum 500kb file available");
        return;
      }
      setFile(selectedFile);
    } else {
      setFile(null);
      setFileError("");
    }
  };

  const handleSaveBlog = (status: string) => {
    status = isEditing ? "update" : "publish";
    setBlogClickStatus(status);

    const sanitizedDescription = DOMPurify.sanitize(blogDescription.trim());
    let isValid = true;

    if (sanitizedDescription.length < 10) {
      setBlogDescError("Blog description must be descriptive");
      isValid = false;
    } else {
      setBlogDescError(""); // Clear error if valid
    }

    if (!file && !previewUrl && !imageURL) {
      setFileError("Image is mandatory");
      isValid = false;
    } else if (file && file.size / 1024 > 500) {
      setFileError("Maximum file size is 500KB");
      isValid = false;
    } else {
      setFileError("");  
    }

    form
      .validateFields()
      .then(() => {
        if (!isValid) {
          return;  
        }

        setLoading(true);
        const formData = new FormData();
        if (file) {
          formData.append("file", file); 
          return uploadImage(formData);  
        } else {
          return addEditBlogCall();
        }
      })
      .catch((error) => {});
  };

  const clearForm = () => {
    form.resetFields();
    setCurrentBlogId(null);
    setIsEditing(false);
    setBlogTitle("");
    setShortDesc("");
    setBlogDescription("");
    setAuthor("");
    setImageURL("");
    setFile(null);
    setBlogDescError("");
  };

  const onSearch = (searchString: string) => {
    let filteredData = blogsData.filter((blog: any) => {
      return (
        blog.title.toLowerCase().includes(searchString.toLowerCase()) ||
        blog.shortDesc.toLowerCase().includes(searchString.toLowerCase()) ||
        blog.author.toLowerCase().includes(searchString.toLowerCase())
      );
    });
    setStateBlogData(filteredData);
  };

  const renderBlogs = () => {
    if (stateBlogData?.length > 0) {
      return (
        <div className="table-container">
          <List
            itemLayout="horizontal"
            dataSource={stateBlogData?.reverse()}
            renderItem={(blog: any) => {
              const updatedAtDate = new Date(
                blog.createdAt
              ).toLocaleDateString();
              return (
                <List.Item
                  key={blog.id}
                  actions={[
                    <Tooltip title="Edit">
                      <Button
                        type="text"
                        color="primary"
                        onClick={() => onEditBlog(blog, userId)}
                      >
                        Edit
                      </Button>
                    </Tooltip>,
                    <Tooltip title="Delete">
                      <Popconfirm
                        title="Are you sure you want to delete this blog?"
                        onConfirm={() => onDeleteBlog(blog.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="text" danger>
                          Delete
                        </Button>
                      </Popconfirm>
                    </Tooltip>,
                  ]}
                  style={{ borderBottom: "1px solid #dddddd", padding: "10px" }}
                >
                  <List.Item.Meta
                    title={<div className="blogTitle">{blog.title}</div>}
                    description={
                      <div className="blogData">
                        <div className="blogImage">
                          <img
                            src={blog.imageURL}
                            alt="blog"
                            className="blogImage"
                          />
                        </div>
                        <div className="blogText">
                          <div>{blog.shortDesc}</div>
                          <div>Author: {blog.author}</div>
                          <div>Uploaded date: {updatedAtDate}</div>
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </div>
      );
    } else {
      return <div className="table-container">No blogs available</div>;
    }
  };

  const handleCancel = () => {
    setAddBlogsOpen(false);
    setIsEditing(false);
    clearForm();
  };
  
  return (
    <>
      <Loader loading={loading} />
      <Layout className="layout-main-blogs layout">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Manage Blogs</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Content className="content-section">
          <div className="mb-10">
            <div className="flexBox">
              <div>
                <Button
                  type="link"
                  onClick={() => {
                    setAddBlogsOpen(true);
                    clearForm();
                  }}
                >
                  <PlusOutlined /> Add Blog
                </Button>
              </div>
              <div>
                <Input
                  placeholder="Search Blogs"
                  onChange={(e) => {
                    onSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {renderBlogs()}
          <Pagination
            total={stateBlogData?.length}
            defaultPageSize={20}
            onChange={() => {}}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            style={{ textAlign: "center", marginTop: 20 }}
          />
        </Content>
      </Layout>
      <Drawer
        placement="right"
        title={isEditing ? "Edit Blog" : "Add a blog"}
        width={720}
        onClose={handleCancel}
        open={addBlogsOpen}
        extra={
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => handleSaveBlog("publish")}
              className="button-publish"
            >
              {isEditing ? "Update" : "Publish"}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="blogTitle"
                label="Blog title"
                rules={[{ required: true, message: "Please enter blog title" }]}
              >
                <Input
                  placeholder="Please enter blog title"
                  onChange={(e) => setBlogTitle(e.target.value)}
                  value={blogTitle}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="urlTitle"
                label="URL Title"
                rules={[{ required: true, message: "Please enter URL title" }]}
              >
                <Input
                  placeholder="Please enter URL title"
                  onChange={(e) => setUrlTitle(e.target.value)}
                  value={urlTitle}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="shortDesc"
                label="Short description"
                rules={[
                  { required: true, message: "Please enter short description" },
                ]}
              >
                <Input
                  placeholder="Please enter short description"
                  onChange={(e) => setShortDesc(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="blogDescription" label="Blog Description">
                <JoditEditor
                  ref={editorRef}
                  value={blogDescription}
                  config={config}
                  onBlur={(newContent) => setBlogDescription(newContent)}
                  onChange={(newContent) => {}}
                />
                <span className="error">{blogDescError}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="imageURL" label="Image URL">
                <div className="card-content">
                  <div className="title mb-10 flexBox">
                    <div className="file-upload-container">
                      <input
                        type="file"
                        accept="image/*"
                        id="file-upload"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <label
                        htmlFor="file-upload"
                        className="file-upload-label"
                      >
                        Choose File
                      </label>
                      <Tooltip title="Upload image for blog">
                        <InfoCircleOutlined className="info-icon" />
                      </Tooltip>
                    </div>
                  </div>
                  <div>
                    {previewUrl ? (
                      <div className="image-preview-container">
                        <img
                          src={previewUrl}
                          alt="Blog preview"
                          className="previewImage"
                        />
                        <CloseOutlined
                          className="remove-icon"
                          onClick={() => {
                            setFile(null);
                            setFileError("");
                            setPreviewUrl("");
                            setImageURL("");
                            if (fileInputRef.current) {
                              fileInputRef.current.value = "";
                            }
                          }}
                        />
                      </div>
                    ) : (
                      imageURL && (
                        <div className="image-preview-container">
                          <img
                            src={imageURL}
                            alt="Blog preview"
                            className="previewImage"
                          />
                          <CloseOutlined
                            className="remove-icon"
                            onClick={() => {
                              setFile(null);
                              setFileError("");
                              setPreviewUrl("");
                              setImageURL("");
                              if (fileInputRef.current) {
                                fileInputRef.current.value = "";
                              }
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <span className="errorText">{fileError}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="category"
                label="Category"
                rules={[
                  { required: true, message: "Please select a category" },
                ]}
              >
                <Select
                  placeholder="Select a category"
                  onChange={(value) => setCategory(value)}
                  value={category}
                >
                  <Select.Option value="Technology">Technology</Select.Option>
                  <Select.Option value="Health">Health</Select.Option>
                  <Select.Option value="Finance">Finance</Select.Option>
                  <Select.Option value="Education">Education</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="author"
                label="Author"
                rules={[
                  { required: true, message: "Please enter your name" },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message:
                      "Author name can only contain alphabets and spaces",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter your name"
                  onChange={(e) => {
                    setAuthor(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  blogsData: state.api.getBlogs?.data,
  userId: state.api.login.data?.id,
  getBlogsState: state.api.getBlogs,
  addBlogsState: state.api.addBlog,
  editBlogState: state.api.editBlog,
  deleteBlogState: state.api.deleteBlog,
  blogId: state.api.getBlogs.data?.id,
  uploadBlogImageState: state.api.imageUpload,
});

const mapDispatchToProps = (dispatch: any) => ({
  addBlogs: (payload: any) => dispatch(addBlog(payload)),
  getBlogs: (payload: any) => dispatch(getBlogs(payload)),
  uploadImage: (payload: FormData) => dispatch(uploadImage(payload)),
  editBlog: (payload: any) => dispatch(editBlog(payload)),
  deleteBlog: (payload: any) => dispatch(deleteBlog(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageBlogs);
