import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Tooltip,
  Button,
  Popconfirm,
  Layout,
  Breadcrumb,
  Input,
  notification,
  Tag,
  Pagination,
  Menu,
  Dropdown,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import CompanyForm from "./CompanyForm";
import "./companyForm.css";
import { connect } from "react-redux";
import { deleteBuyerSupplier, editBuyerSupplier, getBuyerSupplier } from "../../redux/actions/API/buyerSupplier";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../Loader/Loader";
import { AppConstants } from "../../Appconstants";
import BulkUpload from "../../Dashboard/Components/reconcileAndUpload/ReconcileAndUpload";
import { TableRowSelection } from "antd/es/table/interface";
import BulkEdit from "../../Dashboard/Components/bulkEdit/BulkEdit";
import { downloadExcel } from "../../Utility/downloadExcel";
type NotificationType = "success" | "info" | "warning" | "error";
interface IBuyerSupplierInterface {
  buyerSupplierData: any;
  deleteBuyerSupplier: Function;
  deleteBuyerSupplierState: IDataResponse;
  editBuyerSupplierState: IDataResponse;
  getBuyerSupplier: Function;
  editBuyerSupplier: Function;
  companyId: number;
  getBuyerSupplierState: IDataResponse;
}

const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

const BuyerSupplier: React.FC<IBuyerSupplierInterface> = ({
  buyerSupplierData,
  deleteBuyerSupplier,
  deleteBuyerSupplierState,
  editBuyerSupplierState,
  getBuyerSupplier,
  editBuyerSupplier,
  companyId,
  getBuyerSupplierState,
}) => {
  const prevPropsRef = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [isEditBtn, setIsEditBtn] = useState(false);
  const [dataToHandle, setDataToHandle] = useState<any>(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [showAdditionalBtn, setShowAdditionalBtn] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 896);

  // useEffect(() => {
  //   if (selectedRowKeys.length > 0) {
  //     setShowAdditionalBtn(true);
  //   } else {
  //     setShowAdditionalBtn(false);
  //   }
  // }, [selectedRowKeys]);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 414);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (prevPropsRef?.current?.getBuyerSupplierState?.loading && !getBuyerSupplierState?.loading
    ) {
      if (getBuyerSupplierState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }

    if (prevPropsRef?.current?.deleteBuyerSupplierState?.loading && !deleteBuyerSupplierState?.loading
    ) {
      if (deleteBuyerSupplierState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        getBuyerSupplier({companyId: companyId});
        setLoading(false);
        openNotificationWithIcon(
          "success",
          deleteBuyerSupplierState?.data?.message
        );
      }
    }
  
    prevPropsRef.current = {
      getBuyerSupplier,
      editBuyerSupplier,
      getBuyerSupplierState,
      deleteBuyerSupplier,
      deleteBuyerSupplierState,
      editBuyerSupplierState      
    };
  }, [deleteBuyerSupplierState, deleteBuyerSupplier, editBuyerSupplierState]);

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
    setDataToHandle(null);
    setIsViewMode(false);
    setIsEditBtn(false);
  };

  const handleAdd = (newData: any) => {
    setData((prevData) => [...prevData, { ...newData, id: Date.now() }]);
  };

  const handleEdit = (updatedData: any) => {
    setIsOpen(!isOpen);
    setIsEditBtn(true);
    setIsViewMode(false);
    setIsEditMode(true);
    setData((prevData) =>
      prevData.map((item) =>
        item.id === updatedData ? { ...item, ...updatedData } : item
      )
    );
  };

  const handleMenuClick = (item: any, { key }: { key: string }) => {
    if (key === "view") {
      console.log(item)
      setIsOpen(true);
      setDataToHandle(item);
      setIsViewMode(true);
    } else if (key === "edit") {
      console.log(item)
      setIsEditMode(true);
      setIsEditBtn(true);
      setDataToHandle(item);
      setIsOpen(true);
    } else if (key === "delete") {
      handleDelete(item.id);
    }
  };

  const handleDelete = (id: number) => {
    setLoading(true);
    deleteBuyerSupplier({
      id: id,
    });
  };

  const handleBulkDelete = () => {
    const updatedData = selectedRowKeys.filter(
      (row: any) => !selectedRowKeys.includes(row.id)
    );
    setData(updatedData);
    setSelectedRowKeys([]);
  };

  const generateData = () => {
    let companyData: any[] = [];
    buyerSupplierData?.map((elem: any) => {
      companyData.push({
        id: elem.id,
        name: elem.name,
        companyName: elem.companyName,
        companyEmail: elem.companyEmail,
        companyTypes:
          elem.companyType ==
          AppConstants.COMPANY_TYPE_MAPPING.BUYER.COMPANY_TYPE_ID
            ? AppConstants.COMPANY_TYPE_MAPPING.BUYER.COMPANY_TYPE
            : elem.companyType ==
              AppConstants.COMPANY_TYPE_MAPPING.SELLER.COMPANY_TYPE_ID
            ? AppConstants.COMPANY_TYPE_MAPPING.SELLER.COMPANY_TYPE
            : AppConstants.COMPANY_TYPE_MAPPING.BOTH.COMPANY_TYPE,
        companyType: elem.companyType,
        email: elem.email,
        GSTNumber: elem.GSTNumber,
        GSTType: elem.GSTType,
        phone: elem.phone,
        addresses: elem.addresses,
      });
    });
    return companyData;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredCustomers = generateData()?.filter((customers: any) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      customers.companyName?.toLowerCase().includes(searchLower) ||
      customers.companyEmail?.toLowerCase().includes(searchLower) ||
      customers.name?.toLowerCase().includes(searchLower) ||
      customers.phone?.toString().includes(searchLower) ||
      customers.companyTypes?.toLowerCase().includes(searchLower) ||
      customers.dealOwner?.toLowerCase().includes(searchLower)
    );
  });

  const tableData = filteredCustomers;
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = tableData.slice(startIndex, startIndex + pageSize);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<IBuyerSupplierInterface> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a: any, b: any) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Company Email",
      dataIndex: "companyEmail",
      key: "companyEmail",
      sorter: (a: any, b: any) => a.companyEmail.localeCompare(b.companyEmail),
    },
    {
      title: "Contact Person",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Contact No.",
      dataIndex: "phone",
      key: "phone",
      sorter: (a: any, b: any) => a.phone.localeCompare(b.phone),
    },
    {
      title: "Company Type",
      dataIndex: "companyTypes",
      key: "companyTypes",
      sorter: (a: any, b: any) => a.companyTypes.localeCompare(b.companyTypes),
      render: (elem: any, record: any) => (
        <Tag
          color={
            record.companyTypes === "Buyer"
              ? "#2db7f5"
              : record.companyTypes === "Seller"
              ? "#87d068"
              : "#669999"
          }
          key={1}
        >
          {record.companyTypes}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: "120px",
      render: (text: any, record: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="View Details">
            <span
              className="actionIcons"
              onClick={() => handleMenuClick(record, { key: "view" })}
            >
              <EyeOutlined />
            </span>
          </Tooltip>

          <Tooltip title="Edit">
            <span
              className="actionIcons"
              onClick={() => handleMenuClick(record, { key: "edit" })}
            >
              <EditOutlined />
            </span>
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const downloadSelectedRow = () => {
    const selectedData = tableData
      .filter((item: any) => selectedRowKeys.includes(item.id))
      .map((item: any) => ({
        Name: item.name,
        Email: item.email,
        Phone: item.phone,
        CompanyName: item.companyName,
        CompanyEmail: item.companyEmail,
        CompanyType: item.companyTypes,
        Addresses: item.addresses
          .map(
            (address: any) =>
              `${address.addresses}, ${address.city}, ${address.state}, ${address.country}, ${address.pincode}`
          )
          .join(" | "),
      }));
    downloadExcel(selectedData, "Selected_Companies");
  };

  const additionalItems = [
    ...(isMobileView
      ? [
          <Menu.Item key="ManageUOM">
            <span onClick={downloadSelectedRow}>
              <DownloadOutlined /> Download
            </span>
          </Menu.Item>,
        ]
      : []),
  ];

  const menu = <Menu>{additionalItems}</Menu>;

  const breadcrumbItems = [
    { title: 'Dashboard' },
    { title: 'Companies' },
    { title: 'Companies' }
  ];
  return (
    <>
      <Loader loading={loading}></Loader>
      <Layout className="layout">
        <div className="flexBox mb-flexFlow">
        <Breadcrumb items={breadcrumbItems} />

          <div className="mt-10">
            {/* Additional buttons or components can be added here */}
          </div>
        </div>
        <Content className="content-section">
          <div className="mb-10">
            <div className="flexBox">
              <div className="buttonCompanyMobileRow">
                <Button
                  disabled={selectedRowKeys.length > 0}
                  type="link"
                  onClick={toggleDrawer}
                >
                  <PlusOutlined /> Add Company
                </Button>

                <Button
                  disabled={selectedRowKeys.length > 0}
                  type="link"
                  onClick={() => setDrawerVisible(true)}
                >
                  <UploadOutlined /> Bulk Upload
                </Button>

                {!isMobileView ? (
                  <Tooltip title={"Download"}>
                    <span>
                      <Button
                        type="link"
                        onClick={downloadSelectedRow}
                        // disabled={selectedRowKeys.length === 0}
                      >
                        <DownloadOutlined /> Download
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button type="link" className="buttonAddItem">
                      <EllipsisOutlined />
                    </Button>
                  </Dropdown>
                )}
              </div>
              <div className="flexBox">
                {selectedRowKeys.length > 1 && (
                  <div className="flexBox">
                    <Button type="link" danger onClick={handleBulkDelete}>
                      <DeleteOutlined /> Bulk Delete
                    </Button>
                  </div>
                )}
                <Input placeholder="Search Company" className="searchCompanyInputMob" />
              </div>
            </div>
          </div>
          <Table
            bordered
            columns={columns}
            dataSource={paginatedData}
            rowKey="id"
            pagination={false}
            scroll={{ x: 768 }}
            sticky
            rowSelection={rowSelection}
          />
          <Pagination
            total={tableData?.length}
            pageSize={pageSize}
            current={currentPage}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            }}
            showSizeChanger
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            style={{ textAlign: "center", marginTop: 20 }}
          />
        </Content>
      </Layout>
      <CompanyForm
        visible={isOpen}
        onClose={toggleDrawer}
        onAdd={handleAdd}
        onEdit={handleEdit}
        dataToHandle={dataToHandle}
        isDisable={isViewMode}
        isEditMode={isEditMode}
      />
      <BulkUpload
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        title="Company Bulk Upload"
        uploadTemplateText="Company bulk upload template"
        templatePath="company-excel-template"
        resultTitle="Successfully completed bulk uploaded company"
        resultSubTitle="Your excel file for company has been created successfully"
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  buyerSupplierData: state.api.getBuyerSupplier?.data?.reverse(),
  deleteBuyerSupplierState: state.api.deleteBuyerSupplier,
  editBuyerSupplierState: state.api.editBuyerSupplier,
  getBuyerSupplierState: state.api.getBuyerSupplier,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  deleteBuyerSupplier: (payload: any) => dispatch(deleteBuyerSupplier(payload)),
  editBuyerSupplier: (payload: any) => dispatch(editBuyerSupplier(payload)),
  getBuyerSupplier: (payload: any) => dispatch(getBuyerSupplier(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyerSupplier);
