import * as type from '../../types/API/uomType';
import { IAction } from '../userMetadataReducer';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getUOMReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_UOM:
      return {
        ...state,
        loading: true,
      };
    case type.GET_UOM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_UOM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addUOMReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_UOM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_UOM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_UOM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: null
      };
    default:
      return state;
  }
};

export const editUOMReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_UOM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_UOM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_UOM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteUOMReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_UOM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_UOM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_UOM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
