import Table, { ColumnType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { BOMItemDataType } from "./bomConstant";
import { Button, Card, Input, Popconfirm, Select, Tooltip } from "antd";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getItems } from "../../../redux/actions/API/items";
const { Option } = Select;

interface IBOMFinishedGoodProps {
  itemsData: any;
  companyId: number;
  getItems: Function;
}

const BOMFinishedGood: React.FC<IBOMFinishedGoodProps> = ({
  itemsData,
  companyId,
}) => {
  const [keyCounter, setKeyCounter] = useState<number>(2);
  const [bomData, setBOMData] = useState<BOMItemDataType[]>([
    {
      key: 1,
      itemId: "",
      itemName: "",
      UOM: "",
      quantity: null,
      store: "",
      costAllocation: "",
    },
  ]);
  const [errorIndex, setErrorIndex] = useState<number | null>(null);

  useEffect(() => {
    getItems({ companyId: Number(companyId) });
  }, []);

  const addRow = () => {
    const unselectedItemIndex = bomData.findIndex(
      (item) => item.itemId === "" || item.itemName === ""
    );
    if (unselectedItemIndex !== -1) {
      setErrorIndex(unselectedItemIndex);
      return;
    }
    setErrorIndex(null);

    const newItem = {
      key: keyCounter,
      itemId: "",
      itemName: "",
      UOM: "",
      quantity: null,
      store: "",
      costAllocation: "",
    };
    setBOMData([...bomData, newItem]);
    setKeyCounter(keyCounter + 1);
  };

  const handleDelete = (key: React.Key) => {
    const updatedData = bomData.filter((item) => item.key !== key);
    setBOMData(updatedData);
  };

  const handleResetField = (index: number) => {
    const newData = [...bomData];
    newData[index] = {
      ...newData[index],
      itemId: "",
      itemName: "",
      UOM: "",
      quantity: null,
      store: "",
      costAllocation: "",
    };
    setBOMData(newData);
  };

  const selectedItemIds = bomData.map((item) => item.itemId).filter(Boolean);
  const selectedItemNames = bomData
    .map((item) => item.itemName)
    .filter(Boolean);

  const columns: ColumnType<BOMItemDataType>[] = [
    {
      title: "Item ID",
      dataIndex: "itemId",
      key: "itemId",
      fixed: "left",
      render: (text, record, index) => (
        <div>
          <Select
            value={text || null}
            placeholder="Select/Search Item ID"
            showSearch
            // style={{
            //   width: "100%",
            //   minWidth: "6rem", 
            //   maxWidth: "8rem",
            // }}
            onChange={(value) => {
              const selectedItem = itemsData.find(
                (item: any) => item.itemId === value
              );
              setBOMData(
                bomData.map((item) =>
                  item.key === record.key
                    ? {
                        ...item,
                        itemId: value,
                        itemName: selectedItem?.itemName || "",
                        UOM: selectedItem?.metricsUnit || "",
                      }
                    : item
                )
              );
            }}
            filterOption={(input, option) =>
              (option?.value ?? "")
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {itemsData
              .filter((item: any) => !selectedItemIds.includes(item.itemId))
              .map((item: any) => (
                <Option key={item.id} value={item.itemId}>
                  {item.itemId}
                </Option>
              ))}
          </Select>
          {index === errorIndex && !record.itemId && (
            <div style={{ color: "red", marginTop: 4 }}>
              Item ID is required.
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      fixed: "left",

      render: (text, record, index) => (
        <div>
          <Select
            value={text || null}
            placeholder="Select/Search Item Name"
            showSearch
            // style={{
            //   width: "100%",
            //   minWidth: "10rem",  
            //   maxWidth: "15rem",
            // }}
            onChange={(value) => {
              const selectedItem = itemsData.find(
                (item: any) => item.itemName === value
              );
              setBOMData(
                bomData.map((item) =>
                  item.key === record.key
                    ? {
                        ...item,
                        itemId: selectedItem?.itemId,
                        itemName: selectedItem?.itemName || "",
                        UOM: selectedItem?.metricsUnit || "",
                      }
                    : item
                )
              );
            }}
            filterOption={(input, option) =>
              (option?.value ?? "")
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {itemsData
              .filter((item: any) => !selectedItemNames.includes(item.itemName))
              .map((item: any) => (
                <Option key={item.id} value={item.itemName}>
                  {item.itemName}
                </Option>
              ))}
          </Select>
          {index === errorIndex && !record.itemName && (
            <div style={{ color: "red", marginTop: 4 }}>
              Item Name is required.
            </div>
          )}
        </div>
      ),
    },
    {
      title: "UOM",
      dataIndex: "UOM",
      key: "UOM",
      render: (text, record) => (
        <Input
          value={text}
          placeholder="Enter UOM"
          // style={{
          //   width: "100%",
          //   minWidth: "4rem",
          //   maxWidth: "5rem",
          // }}
          onChange={(e) =>
            setBOMData(
              bomData.map((item) =>
                item.key === record.key
                  ? { ...item, UOM: e.target.value }
                  : item
              )
            )
          }
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          value={text || ""}
          type="number"
          placeholder="Enter Quantity"
          // style={{
          //   width: "100%",
          //   minWidth: "4rem",
          //   maxWidth: "6rem",
          // }}
          onChange={(e) =>
            setBOMData(
              bomData.map((item) =>
                item.key === record.key
                  ? { ...item, quantity: Number(e.target.value) }
                  : item
              )
            )
          }
        />
      ),
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      render: (text, record) => (
        <Input
          value={text}
          placeholder="Enter Store"
          // style={{
          //   width: "100%",
          //   minWidth: "10rem", 
          //   maxWidth: "15rem",
          // }}
          onChange={(e) =>
            setBOMData(
              bomData.map((item) =>
                item.key === record.key
                  ? { ...item, store: e.target.value }
                  : item
              )
            )
          }
        />
      ),
    },
    {
      title: "Cost Allocation %",
      dataIndex: "costAllocation",
      key: "costAllocation",
      render: (text, record) => (
        <Input
          value={text}
          placeholder="Enter Cost Allocation"
          // style={{
          //   width: "100%",
          //   minWidth: "6rem",
          //   maxWidth: "8rem",
          // }}
          onChange={(e) =>
            setBOMData(
              bomData.map((item) =>
                item.key === record.key
                  ? { ...item, costAllocation: e.target.value }
                  : item
              )
            )
          }
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 100,
      render: (text: string, record: BOMItemDataType, index: number) => {
        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Refresh">
              <span className="actionIcons">
                <ReloadOutlined onClick={() => handleResetField(index)} />
              </span>
            </Tooltip>
            {bomData?.length > 1 && (
              <Tooltip title="Delete">
                <Popconfirm
                  title="Do you really want to delete this row?"
                  onConfirm={() => handleDelete(record.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span className="actionIcons">
                    <DeleteOutlined />
                  </span>
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  // Do not change its position
  const availableItems = itemsData.filter(
    (item: any) =>
      !bomData.some((row) => row.itemId === item.itemId) && item.itemId
  );

  const disabledAddRow = availableItems.length === 0;

  return (
    <Card className="cardTable">
      <h5 className="mb-2">Finished Good Table</h5>
      <Table
        dataSource={bomData}
        columns={columns}
        pagination={false}
        rowKey="key"
        className="table-component"
        scroll={{ x: "max-content" }}
      />
      <div className="mt-10">
        <Button type="link" onClick={addRow} disabled={disabledAddRow}>
          + Add By Product
        </Button>
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BOMFinishedGood);
