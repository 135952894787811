import {
  CLEAR_INDIVIDUAL_DATA,
  RESET_CREATE_DOCUMENT_DATA,
  SET_ADDITIONAL_DETAIL_DATA,
  SET_ADVANCE_PAYMENT,
  SET_BANK_DETAILS,
  SET_COMMENT_INPUT_DATA,
  SET_COMPANY_DATA,
  SET_DOCUMENT_ADDITIONAL_CHARGES,
  SET_DOCUMENT_ATTACHMENTS_DATA,
  SET_DOCUMENT_FORM_DATA,
  SET_DOCUMENT_ITEM_GRID_DATA,
  SET_DOCUMENT_TYPE,
  SET_DOCUMENTS_SIGNATURE_DATA,
  SET_DOCUMENTS_TERMS_AND_CONDITION_DATA,
  SET_INDIVIDUAL_DATA,
  SET_LOGISTIC_DETAIL_DATA,
  SET_SUPPLIER_DATA,
} from "../../types/UI/createDocument";

const initialState = {
  documentType: "",
  buyerDetails: [],
  supplierDetails: [],
  itemData: [],
  formData: [],
  additionalCharges: [],
  logisticDetails: "",
  additionalDetails: "",
  commentInput: "",
  attachments: [],
  signature: "",
  termsAndCondition: "",
  bankDetails: [],
  advancePayment: 0,
  individualData: {
    buyerName: "",
    buyerEmail: "", 
    contactNumber: "", 
    billingAddress: "",
  },
};

export interface IAction {
  type: string;
  payload?: any;
}

const createDocumentUIReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.payload,
      };

    case SET_COMPANY_DATA:
      return {
        ...state,
        buyerDetails: action.payload,
      };

    case SET_SUPPLIER_DATA:
      return {
        ...state,
        supplierDetails: action.payload,
      };

    case SET_DOCUMENT_ITEM_GRID_DATA:
      console.log(action.payload);
      return {
        ...state,
        itemData: action.payload,
      };

    case SET_DOCUMENT_ADDITIONAL_CHARGES:
      return {
        ...state,
        additionalCharges: action.payload,
      };

    case SET_DOCUMENT_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    case SET_LOGISTIC_DETAIL_DATA:
      return {
        ...state,
        logisticDetails: action.payload,
      };

    case SET_ADDITIONAL_DETAIL_DATA:
      return {
        ...state,
        additionalDetails: action.payload,
      };

    case SET_COMMENT_INPUT_DATA:
      return {
        ...state,
        commentInput: action.payload,
      };

    case SET_DOCUMENT_ATTACHMENTS_DATA:
      return {
        ...state,
        attachments: action.payload,
      };

    case SET_DOCUMENTS_SIGNATURE_DATA:
      return {
        ...state,
        signature: action.payload,
      };

    case SET_DOCUMENTS_TERMS_AND_CONDITION_DATA:
      return {
        ...state,
        termsAndCondition: action.payload,
      };

    case SET_BANK_DETAILS:
      return {
        ...state,
        bankDetails: action.payload,
      };

    case SET_ADVANCE_PAYMENT:
      return {
        ...state,
        advancePayment: action.payload,
      };

    case SET_INDIVIDUAL_DATA:
    return {
      ...state,
      individualData: action.payload,
    };
      
    case CLEAR_INDIVIDUAL_DATA:
      return {
        ...state,
        individualData: { ...initialState.individualData },
      };
      
    case RESET_CREATE_DOCUMENT_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default createDocumentUIReducer;
