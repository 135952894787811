import * as type from "../../types/API/authTypes";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const loginReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case type.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};


export const signupReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.SIGNUP_USER:
      return {
        ...state,
        loading: true,
      };
    case type.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.SIGNUP_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
