import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./ContactUs.css";
import { Button, Form, Input, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  addContactUs,
  deleteContactUs,
  editContactUs,
  getContactUs,
} from "../../redux/actions/API/contactUsAction";
import { connect } from "react-redux";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import help from '../../Utility/images/help.png';

interface IContactUsProps {
  contactUsData: any;
  getContactUs: Function;
  addContactUs: Function;
  editContactUs: Function;
  deleteContactUs: Function;
  getContactUsState: IDataResponse;
  addContactUsState: IDataResponse;
  editContactUsState: IDataResponse;
  deleteContactUsState: IDataResponse;
}

type NotificationType = "success" | "info" | "warning" | "error";

const ContactUs: React.FC<IContactUsProps> = ({
  contactUsData,
  getContactUs,
  getContactUsState,
  addContactUs,
  addContactUsState,
  editContactUs,
  editContactUsState,
  deleteContactUs,
  deleteContactUsState,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>(null);
  const [ipAddress, setIpAddress] = useState("127.0.0.1");
  const prevPropsRef = useRef<any>();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    // getContactUs({ companyId: Number(companyId) });
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => {
        console.error("Failed to fetch IP address:", error);
      });
  }, []);

   useEffect(() => {
      if (
        prevPropsRef?.current?.getContactUsState?.loading &&
        !getContactUsState?.loading
      ) {
        if (getContactUsState?.error) {
          openNotificationWithIcon("error", "Something went wrong!");
        } else {
        //   setLoading(false);
        }
      }
  
      if (
        prevPropsRef?.current?.addContactUsState?.loading &&
        !addContactUsState?.loading
      ) {
        if (addContactUsState?.error) {
          openNotificationWithIcon("error", addContactUsState.error.message);
        } else {
          // getContactUs({ companyId: Number(companyId) });
          openNotificationWithIcon("success", addContactUsState?.data?.message);
          form.resetFields();
        }
      }
  
      if (
        prevPropsRef?.current?.editContactUsState?.loading &&
        !editContactUsState?.loading
      ) {
        if (editContactUsState?.error) {
          openNotificationWithIcon("error", editContactUsState?.error?.message);
        } else {
          // getContactUs({ companyId: Number(companyId) });
          openNotificationWithIcon(
            "success",
            editContactUsState?.data?.message
          );
        }
      }
  
      if (
        prevPropsRef?.current?.deleteContactUsState?.loading &&
        !deleteContactUsState?.loading
      ) {
        if (deleteContactUsState?.error) {
          openNotificationWithIcon("error", "Something went wrong!");
        } else {
          // getContactUs({ companyId: Number(companyId) });
          openNotificationWithIcon(
            "success",
            deleteContactUsState?.data?.message
          );
        }
      }
      prevPropsRef.current = {
        getContactUs,
        addContactUs,
        editContactUs,
        deleteContactUs,
        getContactUsState,
        addContactUsState,
        editContactUsState,
        deleteContactUsState,
      };
    }, [
      getContactUsState,
      addContactUsState,
      deleteContactUsState,
      editContactUsState,
    ]);
  
    const handleSubmit = () => {
        form
          .validateFields()
          .then((values) => {
            const payload = {
              name: values.name,
              email: values.email,
              message: values.message,
            };
            console.log("payload", payload)            
            if (formData) {
              console.log('form data', formData)
              const editPayload = { ...payload, contactUsId: formData?.id };
              editContactUs(editPayload);
            } else {
              console.log("Calling addContactUs with payload")
              addContactUs(payload);
            }
            console.log("response added successfully", values)
          })
          .catch((errorInfo) => {
            console.error("Validation failed:", errorInfo);
          });
      };

  return (
    <>
      <Header />
			<section id="banner-9" className="bg--02 py-70 x-border banner-section">
				<div className="container">

					<div className="banner-7-wrapper">
						<div className="row justify-content-center d-flex align-items-center">


							<div className="col-md-7 col-xl-5">
								<div className="banner-9-txt">

									<h3 className="s-40 w-700">Still need help?</h3>

									<p className="p-lg">Don't hesitate to contact us about any question you might be interested in</p>

								</div>
							</div>


							<div className="col-md-5 col-xl-5">
								<div className="banner-9-img text-end">
									<img className="img-fluid" src={help} alt="banner-image"/ >
								</div>	
							</div>


						</div>  
					</div>  


				</div>  
			</section>	
      <section className="contact-main-container">
        <div className="contact-sub-container-left">
          <h2 className="heading-contact-us">Contact Us</h2>
          <div className="text-contact-intro">
            We would love to hear from you. If you have any <br />
            questions, feedback or suggestions. Please feel
            <br /> free to reach us through our contact details.
          </div>
          <div className="text-email-info">
            <b>Email:</b> <br /> info@easemargin.com
          </div>
          <div className="text-contact-info">
            <b>Phone:</b> <br /> +91 82693 41122, +91 82693 71122
          </div>
          <div className="text-contact-info">
            <b>Office Hours:</b> <br /> Monday - Saturday: 10:00 AM - 7:30 PM
            <br />
            <span>Sunday: Closed</span>
          </div>
          <div className="text-contact-info">
            <b>Address:</b> <br /> Priyanka apartment, Hathital, Jabalpur (M.P),
            482001
          </div>
        </div>

        <div className="contact-sub-container-right">
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input
                placeholder="Enter your Name"
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter your email" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input
                placeholder="Enter your valid Email address"
              />
            </Form.Item>
            <Form.Item
              name="message"
              label="Message"
              rules={[{ required: true, message: "Please enter your message" }]}
            >
              <TextArea
                placeholder="Enter your message"
                rows={4}
              />
            </Form.Item>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Form>
        </div>
      </section>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  // userId: state.api.login.data?.id,
  // companyId: state.api.login.data.companyId,
  contactUsData: state.api.getContactUs?.data,
  getContactUsState: state.api.getContactUs,
  addContactUsState: state.api.addContactUs,
  editContactUsState: state.api.editContactUs,
  deleteContactUsState: state.api.deleteContactUs,
});

const mapDispatchToProps = (dispatch: any) => ({
  getContactUs: (payload: any) => dispatch(getContactUs(payload)),
  addContactUs: (payload: any) => dispatch(addContactUs(payload)),
  editContactUs: (payload: any) => dispatch(editContactUs(payload)),
  deleteContactUs: (payload: any) => dispatch(deleteContactUs(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
