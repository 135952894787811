import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Utility/images/logo.png";
import "./Footer.css";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="footer-info">
              <img className="footer-logo" src={logo} alt="footer-logo" />
              <img className="footer-logo-dark" src={logo} alt="footer-logo" />
              <p className="mt-10">Automate your manufacturing workflow and enhance your margin with ease.</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 col-xl-2">
            <div className="footer-links fl-1">
              <h6 className="s-17 w-700">Company</h6>
              <ul className="foo-links clearfix">
                {/* <li><p><a>About Us</a></p></li> */}
                <li>
                  <p>
                    <Link to="/our-blogs">Our Blog</Link>
                  </p>
                </li>
                <li>
                  <p>
                    <Link to="/contactus">Contact Us</Link>
                  </p>
                </li>
                {/* <li><p><a href="testimonials.html">Customers</a></p></li>
									<li><p><a href="#">Community</a></p></li> */}
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 col-xl-2">
            <div className="footer-links fl-3">
              <h6 className="s-17 w-700">Legal</h6>
              <ul className="foo-links clearfix">
                <li>
                  <p>
                    <Link to="/terms-and-condition">Terms & Conditions</Link>
                  </p>
                </li>
                <li>
                  <p>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </p>
                </li>
                <li>
                  <p>
                    <Link to="/refund-cancellation">Refund and Cancellation</Link>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-sm-6 col-lg-3 col-xl-2">
            <div className="footer-links fl-3">
              <h6 className="s-17 w-700">Feature</h6>
              <ul className="foo-links clearfix">
                <li>
                  <p>
                    <Link to="/services/inventory">Inventory Management</Link>
                  </p>
                </li>
                {/* <li>
                  <p>
                    Production Management
                  </p>
                </li> */}
                <li>
                  <p>
                    <Link to="/services/purchase">Purchase Management</Link>
                  </p>
                </li>
                <li>
                  <p>
                    <Link to="/services/sales">Sales Management</Link>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 d-flex align-items-center">
            <div className="col">
              <div className="footer-copyright">
                <p className="p-sm">
                  &copy; {currentYear} All rights reserved. Designed and developed by
                  Datronix Digital Pvt. Ltd.
                </p>
              </div>
            </div>

            <div className="col">
								<ul className="bottom-footer-socials ico-20 text-end">
									<li><a href="#"><span className="flaticon-twitter"></span></a></li>
									<li><a href="#"><span className="flaticon-instagram"></span></a></li>
								</ul>
							</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
