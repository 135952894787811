import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import MultipleWarehouse from "../../Utility/images/inventoryMain.png";
import PhysicalStock from "../../Utility/images/physica-stock.png";
import BulkUpload from "../../Utility/images/unitofmeasurement.png";
import StockHistory from "../../Utility/images/HistoryImage.png";
import Goods from "../../Utility/images/goods.png";
import StocKeeping from "../../Utility/images/stockskeeping.png";
import ManageProducts from "../../Utility/images/productsmanagement.png";
import Variations from "../../Utility/images/variations.png";
import WOW from "../../Utility/js/wow";
import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./InventoryLanding.css";

const faqData = [
  {
    question: "How does Ease Margin inventory management work ?",
    answer:
      "Ease Margin is designed to manage inventory efficiently and in an easy way with a clear understanding of each item’s description,current stock,category and other related fields.",
  },
  {
    question: "Can I manage multi stores in Ease Margin ?",
    answer:
      "Yes,whether it’s two or more you can manage all stores on one platform with a clear visibility of items and its stock in each store.  ",
  },
  {
    question:
      "I do physical stock reconciliation every month,how can I update the stocks in Ease Margin ?",
    answer:
      "We provide you an option to manually update the product stocks with the physical stock recognition option.It will help you to update the stocks in bulk.",
  },
  {
    question:
      "I want to track the item’s stock history as well,do Ease Margin provide history tracking ?",
    answer:
      "You can track stock every time it gets changed and a history gets created with every necessary detail. The same can be seen through the history icon provided beside each item in our actions section.",
  },
  {
    question: "Can I manage the multiple item categories ? ",
    answer:
      "Yes,you have an option to divide and manage the items based on their categories and further you can also divide and track the sub-categories as well for the item.",
  },
];

const InventoryLanding: React.FC = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<number | null>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    try {
      const wow = new WOW.WOW();
      wow.init();
    } catch (error) {
      console.error("Error initializing WOW.js:", error);
    }
  }, []);

  useEffect(() => {
    const observers: IntersectionObserver[] = [];

    cardRefs.current.forEach((card, index) => {
      if (card) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              card.classList.add("inventory-card-transition");
            } else {
              card.classList.remove("inventory-card-transition");
            }
          });
        });
        observer.observe(card);
        observers.push(observer);
      }
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, []);

  const navigateToSignUp = () => {
    navigate("/sign-in", { state: { isSignUp: true } });
  };

  const toggleCard = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };
  return (
    <>
      <Header />
      <div className="inventory-landing-container">
        {/* <section className="section-inventory-details">
          <h2 className="heading-inventory-intro">Inventory Managment</h2>
          <div className="paragh-text-intro">
            Free inventory management software designed to help your business!
          </div>

          <div className="container-inventoryDetailed-cards">
            <div className="inventoryDetailedColumn">
              <div className="inventoryDetailedCardOne"></div>
              <div className="inventoryDetailedcardTwo"></div>
              <div className="inventoryDetailedcardTwo"></div>
              <div className="inventoryDetailedCardOne"></div>
            </div>

            <div></div>

            <div className="inventoryDetailedMainCard"></div>

            <div></div>

            <div className="inventoryDetailedColumn">
              <div className="inventoryDetailedCardThree"></div>
              <div className="inventoryDetailedcardFour"></div>
              <div className="inventoryDetailedcardFour"></div>
              <div className="inventoryDetailedCardThree"></div>
            </div>
          </div>
        </section> */}

        <section className="section-inventory-feature">
          <div className="text-inventory-feature">
            Inventory Tracking Software to keep track of your sales activity.
          </div>
        </section>

        <h2 className="text-heading-feature">Features</h2>

        <section className="section-multiple-warehouse">
          <div className="inventory-container-row left-column wow fadeInRight txt-block">
            <img
              src={MultipleWarehouse}
              alt="multiple-warehouse"
              className="image-multiple-warehouse"
            />
            <div className="inventory-container-column container-multiple-warehouse">
              <h4>Manage Multiple Stores</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15 content-row">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Create multiple stores and manage stocks as per store.
                    </p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Get alets and notification for low stocks as per store.
                    </p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Get stocks valuation as per store.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Negative stocks restriction as per store.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Realtime update of stocks as per document creation.</p>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </section>

        <section className="section-physical-stock">
          <div className="inventory-container-row right-column wow fadeInLeft txt-block">
            <div className="inventory-container-column container-phy-stock">
              <h4>Physical Stock Reconciliation and update stock in bulk</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Update product stocks by checking physical stock as per
                      stores.
                    </p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Manually update stocks for adjustments as per store.</p>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <img
              src={PhysicalStock}
              alt="physical-stock"
              className="image-phy-stock"
            />
          </div>
        </section>

        <section className="section-bulk-upload">
          <div className="inventory-container-row left-column wow fadeInRight txt-block">
            <img
              src={BulkUpload}
              alt="bulk-upload"
              className="image-bulk-upload"
            />
            <div className="inventory-container-column container-bulk-upload">
              <h4>Custom Fields and Alternate Units</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Create custom fields on item level and fetch it on
                      documents.
                    </p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Manage multiple units for single items and get realtime{" "}
                      <br /> stocks as per different units.
                    </p>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </section>

        <section className="section-alternate-units">
          <div className="inventory-container-row right-column wow fadeInLeft txt-block">
            <div className="inventory-container-column container-phy-stock">
              <h4>Stock History</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Get realtime item level history as per stores and units.
                    </p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Store level history for better tracking.</p>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <img
              src={StockHistory}
              alt="physical-stock"
              className="image-stock-history"
            />
          </div>
        </section>

        <section ref={wrapperRef} className="section-inventory-cards">
          <div className="heading-inventory-card">
            Try it for free. No payments required.
          </div>
          <Button
            className="button-inventory-signup"
            type="default"
            onClick={() => navigateToSignUp()}
          >
            Sign up for free
          </Button>
          <div className="inventory-card-row">
            {[
              {
                image: Goods,
                title: "Accurate stock keeping",
                description:
                  "Order confidently and save money by using EaseMargin’s inventory management software. EaseMargin gives your business control over your product stocks.",
              },
              {
                image: StocKeeping,
                title: "Improve your stock keeping",
                description:
                  "Improve speed and efficiency, remove error or fraud, and reduce time-consuming manual tasks. Simple and easy to use across your entire organization.",
              },
              {
                image: ManageProducts,
                title: "Manage all your products",
                description:
                  "Full control of all your products anywhere, anytime, from any device. See where every Rupee goes and monitor the bottom-line impact with real-time budgeting.",
              },
              {
                image: Variations,
                title: "Comprehensive product variation",
                description:
                  "Add and manage products with distinct variations like color, size, price & more. Curate and manage your evolving inventory from a single dashboard.",
              },
            ].map((card, index) => (
              <div
                key={index}
                ref={(el) => (cardRefs.current[index] = el)}
                className="inventory-cards inventory-card-transition"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="icon-inventory-cards"
                />
                <h3 className="headingh3">{card.title}</h3>
                <div className="paraghtextCards">{card.description}</div>
              </div>
            ))}
          </div>
        </section>

        <section className="section-faqs">
          <h2 className="heading-frequently-asked">
            <span className="span-intro">Frequently Asked Questions</span>
          </h2>
          <p className="paragh-faq">
            If you can't find an answer that you're looking for, feel free to
            drop us a line.
          </p>
          <div className="faqs-button">
            <Button type="default" className="button-sub-faq">
              About the company
            </Button>
            <Button type="default" className="button-sub-faq ml-10">
              Contact Support
            </Button>
          </div>
          <div className="faq-cards">
            {faqData.map((faq, index) => (
              <div key={index} className="faq-card">
                <div className="faq-question" onClick={() => toggleCard(index)}>
                  <span>{faq.question}</span>
                  {expanded === index ? <UpOutlined /> : <DownOutlined />}
                </div>
                {expanded === index && (
                  <div className="faq-answer">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
      <div className="inventory-footer-container">
        <Footer />
      </div>
    </>
  );
};

export default InventoryLanding;
